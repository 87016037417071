<div class="flex flex-column gap-2 h-full page-container">
  <div class="text-center">Testing gantt chart</div>
  <form class="filters" [formGroup]="filterForm" *ngIf="filterForm">
    <div class="filterItem">
      <select class="form-control form-select selectItem light-blue-border" id="filterTimeRange"
              formControlName="timespan" [ngClass]="{ 'selected-color': filterForm.value.timespan }">
        <option [ngValue]="null">Time Span</option>
        <option *ngFor="let val of timespanHours" [ngValue]="val">
          -4h | +{{ val?.toString().padStart(2, '0') }}h
        </option>
      </select>
    </div>


    <div class="filterItem">
      <input class="form-control custom-input light-blue-border" placeholder="Flight Number" id="flightNumber"
             formControlName="flightNumber" data-test="gantt-filter-flight-number-input"/>
    </div>

    <div class="filterItem">
      <input class="form-control custom-input light-blue-border" placeholder="AC Registration"
             [(ngModel)]="acRegistrationFilter" [ngModelOptions]="{ standalone: true }"
             data-test="gantt-filter-ac-reg-input"/>
    </div>

    <div class="filterItem">
      <!--      <input class="form-control custom-input light-blue-border" placeholder="AC Type" [(ngModel)]="acTypeFilter"-->
      <!--             [ngModelOptions]="{ standalone: true }"-->
      <!--             data-test="gantt-filter-ac-type-input"/>-->

      <ng-select [(ngModel)]="acTypeFilter" [ngModelOptions]="{ standalone: true }"
                 data-test="overlap-alert-delay-code-select" class="select-bg" placeholder="AC Type">
        <ng-option *ngFor="let acType of acTypesArray"
                   [value]="acType.id">{{ acType.alias }}
        </ng-option>
        <ng-template ng-option-tmp let-item="item">
          <span class="ng-option-label"
                [attr.data-test]="'ac-type-' + item">{{ acTypes?.[item] }}</span>
        </ng-template>
      </ng-select>
    </div>

    <div class="filterItem">
      <ng-select class="new-select" placeholder="Airport" id="filterAirport" formControlName="station"
                 data-test="flight-filter-station-select" [inputAttrs]="{ 'data-test': 'station-input'}">
        <ng-container *ngIf="user?.role !== 'DUTY_MANAGER'; else dutyManagerAirportFilter">
          <ng-option *ngFor="let station of stations" [value]="station">{{ station }}</ng-option>
          <ng-template ng-option-tmp let-item="item">
            <span class="ng-option-label" [attr.data-test]="'station-' + item">{{ item }}</span>
          </ng-template>
        </ng-container>
        <ng-template #dutyManagerAirportFilter>
          <ng-option [value]="user?.location">{{ user?.location }}</ng-option>
          <ng-template ng-option-tmp>
            <span class="ng-option-label" [attr.data-test]="'station-' + user?.location">{{ user?.location }}</span>
          </ng-template>
        </ng-template>
      </ng-select>
    </div>

    <div class="filterItem">
      <ng-select class="new-select" placeholder="Status" id="filterStatus" formControlName="status"
                 data-test="flight-filter-status-select" [inputAttrs]="{ 'data-test': 'status-input'}">
        <ng-option value="OK">Green</ng-option>
        <ng-option value="WARNING">Amber</ng-option>
        <ng-option value="ERROR">Red</ng-option>
      </ng-select>
    </div>
    <div class="filterItem">
      <ng-select class="new-select" placeholder="Alerts" id="filterAlerts" formControlName="alerts"
                 data-test="flight-filter-alert-select" [inputAttrs]="{ 'data-test': 'alert-input'}">
        <ng-option [value]="true">with Alerts</ng-option>
        <ng-option [value]="false">without Alerts</ng-option>
      </ng-select>
    </div>

    <div class="filterItem show-cancelled">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="show-cancelled"
               formControlName="showCancelled"
               data-test="gantt-filter-show-cancelled-check">
        <label class="form-check-label dark-blue" for="show-cancelled">Show cancelled sectors</label>
      </div>
    </div>

    <div class="filterItem show-unassigned">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="show-unassigned"
               formControlName="showUnassigned"
               data-test="gantt-filter-show-unassigned-check">
        <label class="form-check-label dark-blue" for="show-unassigned">Show unassigned sectors</label>
      </div>
    </div>
  </form>
  <app-gantt-chart [registrations]="registrations" [data]="legs"
                   [timespan]="filterForm?.value.timespan" (refreshRequired)="refreshData()"
                   [showLoadingSpinner]="loadingData" [acTypes]="acTypes"
                   [showUnassigned]="filterForm?.value?.showUnassigned || filterForm?.value?.showCancelled"
                   [(selectedSectors)]="selectedSectors"
                   (selectedSectorsChange)="slider?.updateAlertFilteredList()"
                   [changes]="changes"
                   [(cancellationChanges)]="cancellationChanges" [(rescheduleChanges)]="rescheduleChanges"
                   [(registrationChanges)]="registrationChanges" [(selectedSectorsId)]="selectedSectorsId"
                   [(selectedPairId)]="selectedPairId"
                   (selectedPairIdChange)="onPairSelected($event)"
                   [(acRegistrationFilter)]="acRegistrationFilter" [(acTypeFilter)]="acTypeFilter"
                   (openFlightInfo)="slider.sliderOpen = true; slider.sliderTab = GanttSliderTab.FLIGHT_INFO"
                   #chart></app-gantt-chart>
</div>
<app-gantt-slider [cancellationChanges]="cancellationChanges" [alerts]="alerts"
                  [registrationChanges]="registrationChanges"
                  [rescheduleChanges]="rescheduleChanges" [alertPermission]="alertPermission"
                  [selectedSectors]="selectedSectors" #slider (updateAlertsRequired)="refetchAlerts()"
                  (undoClicked)="chart.undoChanges($event)" [data]="legs" [passengerCategories]="passengerCategories"
                  [passengerClasses]="passengerClasses" [delayCodes]="delayCodes"
                  [delayCodesKV]="delayCodesKV" [selectedSectorIds]="selectedSectorsId"
                  (updateGanttDataRequired)="refreshData()" [selectedPairId]="selectedPairId"
                  (saveInProgress)="saveInProgress = $event ? null : undefined" [registrations]="registrations"
                  (saveClicked)="saveChanges($event)" [saveTypeInProgress]="saveInProgress"
                  (legChanged)="refreshData()"></app-gantt-slider>

<div *ngIf="saveInProgress !== undefined" class="save-screen">

</div>
