import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import dayjs from 'dayjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {calculateETADate, dayjsToNgbDate, ngbDateToDayjs, ngbDateToFormat} from '../../../../shared/utils/utils';
import {takeUntil} from 'rxjs/operators';
import {IAlertsModel} from '../../../../shared/models/alerts.model';
import {PairsService} from '../../../../services/pairs.service';
import {IPairDetail} from '../../../../shared/models/pair-detail.model';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {PairLegTimesLogService} from '../../../../services/pair-leg-times-log.service';
import {LegDelaysLogService} from '../../../../services/leg-delays-log.service';
import {LegsService} from '../../../../services/legs.service';
import {GeneralSettingsService} from '../../../../services/general-settings.service';
import {TimeTypesService} from '../../../../services/time-types.service';
import {ToastService} from '../../../../services/toast.service';
import {IPairOverview} from '../../../../shared/models/pair-overview.model';
import {IDelayCode} from '../../../../shared/models/delay-code.model';
import {IGenericContainerObject} from '../../../../shared/models/genericContainerObject.model';

@Component({
  selector: 'app-overlap-alert-type',
  templateUrl: './overlap-alert-type.component.html',
  styleUrls: ['./overlap-alert-type.component.scss']
})
export class OverlapAlertTypeComponent implements OnInit, OnDestroy, OnChanges {

  @Input() alert: IAlertsModel;
  @Input() timezone: string;
  @Input() dateFormat: string;
  @Input() delayCodes: IDelayCode[];
  delayCodesKV: IGenericContainerObject<IDelayCode> = {};
  pairDetails: IPairDetail[];
  destroy$ = new Subject();
  delayInMinutes: number;
  firstPreviousPair: IPairOverview;
  secondPreviousPair: IPairOverview;
  showContent = false;
  form: UntypedFormGroup;
  isBusy = false;

  constructor(private pairService: PairsService,
              private pairLegTimesLogService: PairLegTimesLogService,
              private legDelaysLogService: LegDelaysLogService,
              private activeModal: NgbActiveModal,
              private legService: LegsService,
              private generalSettingsService: GeneralSettingsService,
              private timeTypesService: TimeTypesService,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.handleOverlappingSectorsAlert();
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.delayCodes) {
      this.delayCodes?.forEach((delay) => {
        this.delayCodesKV[delay.id] = delay;
      });
    }
  }

  async handleOverlappingSectorsAlert() {

    const legId = this.alert.__pair__.departureLegId;
    const results = await Promise.all([firstValueFrom(this.pairService.getPairsByFilter({
      departureLegId: legId,
      isActive: false
    })), firstValueFrom(this.pairService.getPairsByFilter({arrivalLegId: legId}))]);
    let pairsAsDeparture = results[0];
    let pairsAsArrival = results[1];
    console.log('pairs as arrival:', pairsAsArrival);
    pairsAsDeparture = pairsAsDeparture.filter((pair) => pair.arrivalLegId !== null);
    pairsAsArrival = pairsAsArrival.filter((pair) => pair.departureLegId !== null);
    console.log('pairs as arrival after filtering:', pairsAsArrival);
    pairsAsDeparture.sort((first, second) => {
      return first.id < second.id ? 1 : -1;
    });
    pairsAsArrival.sort((first, second) => {
      return first.id < second.id ? 1 : -1;
    });
    this.firstPreviousPair = pairsAsDeparture[0];

    this.secondPreviousPair = pairsAsArrival[0];

    const promises = [firstValueFrom(this.pairService.getPairDetail(pairsAsDeparture[0].id))];
    if (pairsAsArrival[0]) {
      promises.push(firstValueFrom(this.pairService.getPairDetail(pairsAsArrival[0].id)));
    }
    this.pairDetails = await Promise.all(promises);
    this.firstPreviousPair.dateOfOperation = this.pairDetails[0].dateOfOperation as unknown as Date;
    this.firstPreviousPair.airlineDesignator = this.pairDetails[0].arrivalLeg?.airlineDesignator;
    this.firstPreviousPair.flightNumberArriving = this.pairDetails[0].arrivalLeg?.flightNumber;
    this.firstPreviousPair.flightNumberDeparting = this.pairDetails[0].departureLeg?.flightNumber;
    this.firstPreviousPair.std = this.pairDetails[0]?.departureLeg?.std;
    this.firstPreviousPair.sta = this.pairDetails[0]?.arrivalLeg?.sta;
    this.firstPreviousPair.etd = this.pairDetails[0]?.departureLeg?.etd;
    this.firstPreviousPair.eta = this.pairDetails[0]?.arrivalLeg?.eta;
    this.firstPreviousPair.atd = this.pairDetails[0]?.departureLeg?.atd;
    this.firstPreviousPair.ata = this.pairDetails[0]?.arrivalLeg?.ata;
    this.firstPreviousPair.ctot = this.pairDetails[0]?.ctot;
    this.firstPreviousPair.groundTime = dayjs(this.pairDetails[0]?.departureLeg.tod).utc().diff(dayjs(this.pairDetails[0]?.arrivalLeg.toa).utc(), 'm');

    if (this.pairDetails.length === 2 && this.pairDetails[1].departureLeg?.operationStatus === 'operating') {
      this.secondPreviousPair.dateOfOperation = this.pairDetails[1].dateOfOperation as unknown as Date;
      this.secondPreviousPair.airlineDesignator = this.pairDetails[1].arrivalLeg?.airlineDesignator;
      this.secondPreviousPair.flightNumberArriving = this.pairDetails[1].arrivalLeg?.flightNumber;
      this.secondPreviousPair.flightNumberDeparting = this.pairDetails[1].departureLeg?.flightNumber;
      this.secondPreviousPair.std = this.pairDetails[1]?.departureLeg?.std;
      this.secondPreviousPair.sta = this.pairDetails[1]?.arrivalLeg?.sta;
      this.secondPreviousPair.etd = this.pairDetails[1]?.departureLeg?.etd;
      this.secondPreviousPair.eta = this.pairDetails[1]?.arrivalLeg?.eta;
      this.secondPreviousPair.atd = this.pairDetails[1]?.departureLeg?.atd;
      this.secondPreviousPair.ata = this.pairDetails[1]?.arrivalLeg?.ata;
      this.secondPreviousPair.ctot = this.pairDetails[1]?.ctot;
      this.secondPreviousPair.groundTime = dayjs(this.pairDetails[1]?.departureLeg.tod).utc().diff(dayjs(this.pairDetails[1]?.arrivalLeg.toa).utc(), 'm');
    }

    // const delay = dayjs(this.pairDetails[0].arrivalLeg?.etd ?? this.pairDetails[0].arrivalLeg.std).diff(this.pairDetails[0].arrivalLeg.std, 'minutes');
    // const newEtd = dayjs(this.pairDetails[0]?.departureLeg?.etd ?? this.pairDetails[0]?.departureLeg.std).utc().add(delay, 'minutes');
    const minTurnaroundTime = this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__?.acTypeModel?.defaultMinTurnaroundTimeInMinutes;
    const newEtd = this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__.ata ? dayjs(this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__.ata).utc().add(minTurnaroundTime, 'minutes') : dayjs(this.alert.__pair__.__prevDeparturePairModel__?.__arrivalLegModel__.eta).utc().add(minTurnaroundTime, 'minutes');
    this.form = new UntypedFormGroup({
      etdDate: new UntypedFormControl(dayjsToNgbDate(newEtd), Validators.required),
      newEtdTime: new UntypedFormControl(newEtd.format('HH:mm'), Validators.required),
      delayCode: new UntypedFormControl('', Validators.required),
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(item => this.updateDelayTimespan(item));
    this.form.patchValue({delayCode: ""});
    this.showContent = true;
  }

  updateDelayTimespan(item: { etdDate: NgbDateStruct, newEtdTime: string, code: string }) {
    console.log('Update:', item);
    if (!item.etdDate || !item.newEtdTime
      || !dayjs([item.etdDate.year, item.etdDate.month - 1, item.etdDate.day]).isValid() || item.newEtdTime.length !== 5) {
      return;
    }
    const newDelayMoment = dayjs(ngbDateToFormat(item.etdDate, this.dateFormat) + ' ' + item.newEtdTime + '+00:00', this.dateFormat + ' HH:mmZ');
    const stdMoment = dayjs(this.pairDetails[0]?.departureLeg?.std);
    this.delayInMinutes = newDelayMoment.diff(stdMoment, 'minutes');
  }

  async save() {
    const leg = this.pairDetails[0].departureLeg;
    const formValue = this.form.value;
    this.isBusy = true;
    const etdTimeSplit = formValue.newEtdTime.split(':');
    const newEtd = ngbDateToDayjs(formValue.etdDate).utc(true).hour(etdTimeSplit[0]).minute(etdTimeSplit[1]).toDate();
    const newEta = calculateETADate({...leg, etd: newEtd});
    const saveResult = await firstValueFrom(this.legService.saveLeg({
      ...leg,
      etd: newEtd,
      tod: newEtd,
      eta: newEta
    } as any));
    if (!saveResult) {
      return;
    }
    this.toastService.showSuccess('Leg has been saved');
    const timeTypes = await firstValueFrom(this.timeTypesService.getTimeTypes());
    if (!timeTypes) {
      return;
    }
    const pairLegTimesLogId = await firstValueFrom(this.pairLegTimesLogService.getPairLegTimeLogs({
      isActive: true,
      legId: leg.id,
      timeTypeId: timeTypes.filter((item) => item.identifier === 'ETD')[0]?.id
    }));
    const delayResult = await firstValueFrom(this.legDelaysLogService.saveLegDelayLog({
      legId: leg.id,
      delayCodeId: formValue.delayCode,
      minutes: this.delayInMinutes,
      pairLegTimesLogId: pairLegTimesLogId[0]?.id ?? null,
      issueEtd: true,
    } as any));
    if (delayResult) {
      this.toastService.showSuccess('Leg delay has been saved');
    }
    this.closeModal(true);
  }

  closeModal(changed = false) {
    this.activeModal.dismiss(changed);
  }
}
