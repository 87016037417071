<div class="p-2 position-relative alert-list-container">
  <div class="flex flex-column">
    <div class="flex">
      <div class="col-3">
        <form class="flex search-section justify-content-between">
          <div class="input-group has-search search-box w-100">
            <img src="content/images/icons/search.svg" width="26" height="26" class="form-control-feedback"
                 alt="search">
            <input type="text" class="form-control search-bar input-bg-color light-blue-border" placeholder="Search"
                   [(ngModel)]="alertSearchText" [ngModelOptions]="{ standalone: true }"
                   (ngModelChange)="alertSearchTextChange.emit($event)"
                   data-test="alerts-search-input">
          </div>
        </form>
      </div>
      <div class="col-9 flex align-items-end justify-content-end">
        <div class="flex gap-3">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="critical"
                   (change)="severitySwitched(localAlertSeverity.CRITICAL)"
                   [checked]="severityFilters.includes(localAlertSeverity.CRITICAL)"
                   data-test="alerts-critical-severity-check">
            <label class="form-check-label dark-blue" for="critical">Critical</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="intermediate"
                   (change)="severitySwitched(localAlertSeverity.INTERMEDIATE)"
                   [checked]="severityFilters.includes(localAlertSeverity.INTERMEDIATE)"
                   data-test="alerts-intermediate-severity-check">
            <label class="form-check-label dark-blue" for="intermediate">Intermediate</label>
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="low"
                   (change)="severitySwitched(localAlertSeverity.LOW)"
                   [checked]="severityFilters.includes(localAlertSeverity.LOW)" data-test="alerts-low-severity-check">
            <label class="form-check-label dark-blue" for="low">Low</label>
          </div>
        </div>
      </div>
    </div>
    <app-alert-list class="avitium-scrollbar mt-3" [alertList]="alerts"
                    (remindMeLaterClicked)="remindLater($event)"
                    (sortChanged)="sortChanged()" (localFilteringRequired)="updateRequired.emit()"
                    [user]="user" [sortOrder]="sortOrder" (refetchRequired)="refetchRequired.emit()"
                    (legChanged)="legChanged.emit()"></app-alert-list>
  </div>

</div>
