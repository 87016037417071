import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {IGanttLeg} from "../../../shared/models/leg-gantt.model";
import {GanttChangesType, ICancellation, IRescheduleChange, ITailChange} from "../../../shared/models/gantt.models";
import {IAlertsModel} from "../../../shared/models/alerts.model";
import dayjs from "dayjs";
import {AlertService} from "../../../services/alert.service";
import {AlertSeverity} from "../../../shared/constants/alert-severity.enum";
import {SortDirection} from "../../../shared/constants/table-sort.types";
import {interval, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {IGanttChartData} from "../../../shared/models/gantt-chart-data.model";
import {ISimpleData} from "../../../shared/models/simpleData.model";
import {IPassengerCategory} from "../../../shared/models/passenger-categories.model";
import {IDelayCode} from "../../../shared/models/delay-code.model";
import {IWeatherMetarModel} from "../../../shared/models/weather-metar.model";
import {IWeatherTafModel} from "../../../shared/models/weather-taf.model";
import {WeatherService} from "../../../services/weather.service";
import {AirportsService} from "../../../services/airports.service";
import {IAirport} from "../../../shared/models/airport.model";
import {GanttSliderTab} from 'src/app/shared/constants/enums';
import {IAcRegistration} from "../../../shared/models/ac-registration.model";

@Component({
  selector: 'app-gantt-slider',
  templateUrl: './gantt-slider.component.html',
  styleUrls: ['./gantt-slider.component.scss']
})
export class GanttSliderComponent implements OnChanges, OnDestroy, OnInit {
  protected readonly GanttSliderTab = GanttSliderTab;
  sliderTab: GanttSliderTab;
  sliderOpen: boolean;
  filteredAlerts: IAlertsModel[] = [];
  alertSearchText: string = '';
  alertsToRemind: { [alertId: number]: number } = {};
  severityFilters: AlertSeverity[] = [AlertSeverity.CRITICAL, AlertSeverity.INTERMEDIATE, AlertSeverity.LOW];
  sortOrder: SortDirection = 'desc';
  @Input() data: IGanttChartData;
  @Input() selectedLeg: IGanttLeg;
  @Input() selectedSectorIds: number[];
  @Input() registrationChanges: ITailChange[];
  @Input() rescheduleChanges: IRescheduleChange[];
  @Input() cancellationChanges: ICancellation[];
  @Input() pendingChanges: number;
  @Input() alertPermission?: boolean;
  @Input() alerts?: IAlertsModel[];
  @Input() passengerClasses: ISimpleData[];
  @Input() passengerCategories: IPassengerCategory[];
  @Input() selectedSectors: Record<number, boolean>;
  @Input() delayCodes: IDelayCode[];
  @Input() delayCodesKV: Record<number, IDelayCode>;
  @Input() selectedPairId?: number;
  @Input() registrations?: IAcRegistration[];
  @Input() saveTypeInProgress: GanttChangesType;
  @Output() saveClicked = new EventEmitter<GanttChangesType>();
  @Output() undoClicked = new EventEmitter<GanttChangesType>();
  @Output() updateAlertsRequired = new EventEmitter();
  @Output() updateGanttDataRequired = new EventEmitter();
  @Output() saveInProgress = new EventEmitter<boolean>();
  @Output() legChanged = new EventEmitter<void>();

  unsubscribe$ = new Subject<void>();

  metarData: { [iata: string]: IWeatherMetarModel } = {};
  tafData: { [iata: string]: IWeatherTafModel } = {};
  airports?: IAirport[];


  constructor(private alertService: AlertService, private weatherService: WeatherService, private airportsService: AirportsService, private cdr: ChangeDetectorRef) {
    const alertsToRemindStr = localStorage.getItem('alerts_to_remind');
    if (alertsToRemindStr) {
      this.alertsToRemind = JSON.parse(localStorage.getItem('alerts_to_remind'));
    }
  }

  get firstSelectedLeg() {
    return this.data?.[this.selectedSectorIds?.[0]]?.leg;
  }

  ngOnInit() {
    interval(5000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      let updateStorage = false;
      for (const key in this.alertsToRemind) {
        const timeToShowAlert = this.alertsToRemind[key];
        if (timeToShowAlert < dayjs().unix()) {
          updateStorage = true;
          delete this.alertsToRemind[key];
        }
      }
      if (updateStorage) {
        localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
        this.updateAlertFilteredList();
      }
    });

    this.airportsService.fetchAirports().subscribe((result) => {
      this.airports = result;
      this.weatherService.getWeatherMetarData().subscribe((metars) => {
        for (const metar of metars) {
          const iata = this.airports.find((air) => air.icao === metar.icao)?.iata;
          if (!iata) {
            continue;
          }
          this.metarData[iata] = metar;
        }
      });
      this.weatherService.getWeatherTafData().subscribe((tafs) => {
        for (const taf of tafs) {
          const iata = this.airports.find((air) => air.icao === taf.icao)?.iata;
          if (!iata) {
            continue;
          }
          this.tafData[iata] = taf;
        }

      })
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.alerts || changes.selectedSectors) {
      this.updateAlertFilteredList();
    }
    if (changes.registrationChanges || changes.rescheduleChanges || changes.cancellationChanges) {
      this.updatePendingChangesCount();
    }
  }

  onTogglerClick(tab: GanttSliderTab) {
    this.sliderOpen = this.sliderTab !== tab;
    if (!this.sliderOpen) {
      this.sliderTab = null;
      return;
    }
    this.sliderTab = tab;
  }


  protected readonly GanttChangesType = GanttChangesType;

  undoChanges(type: GanttChangesType) {
    this.undoClicked.emit(type);
  }

  updateAlertFilteredList(): void {
    if (!this.alerts) {
      return;
    }
    if (!Object.keys(this.alertService.alertTypes)?.length) {
      setTimeout(() => this.updateAlertFilteredList(), 200);
      return;
    }
    const search: string = this.alertSearchText;
    this.filteredAlerts.splice(0, this.filteredAlerts.length);
    const haveSelectedFlights = !!Object.keys(this.selectedSectors ?? {})?.length;
    this.filteredAlerts.push(...this.alerts.filter((x) => {
      if (haveSelectedFlights) {
        if (!this.selectedSectors[x.__pair__?.__arrivalLegModel__?.id] && !this.selectedSectors[x.__pair__?.__departureLegModel__?.id] && !this.selectedSectors[x.legId]) {
          return false;
        }

      }
      return (this.alertsToRemind ? (this.alertsToRemind[x.id] == null || this.alertsToRemind[x.id] < dayjs().unix()) : true) && (this.alertService.alertTypes[x.alertTypeId]?.description.toLowerCase().includes(search.toLowerCase()) || x.__leg__?.flightNumber?.toLowerCase().includes(search.toLowerCase()) || x.__pair__?.__arrivalLegModel__?.flightNumber?.toLowerCase()?.includes(search.toLowerCase()) || x.__pair__?.__departureLegModel__?.flightNumber?.toLowerCase()?.includes(search.toLowerCase())) && (this.severityFilters ? this.severityFilters.includes(this.alertService.alertTypes[x.alertTypeId].severity) : true)
    }));
    const sortOrderFactor = this.sortOrder === 'asc' ? 1 : -1;
    this.filteredAlerts.sort((a, b) => (a.occuredAt > b.occuredAt ? 1 : -1) * sortOrderFactor);
  }


  remindLater(data: { id: number; hours: number; minutes: number }) {
    this.alertsToRemind[data.id] = dayjs().add(data.hours, 'hours').add(data.minutes, 'minutes').unix();
    localStorage.setItem('alerts_to_remind', JSON.stringify(this.alertsToRemind));
  }

  sortChanged() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  }

  updatePendingChangesCount() {
    this.pendingChanges = this.registrationChanges.length + this.rescheduleChanges.length + this.cancellationChanges.length;
  }
}
