import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import dayjs from "dayjs";
import {IAcRegistration} from "../../models/ac-registration.model";
import {ILegsModel} from "../../models/legs.model";
import {IGanttChartData} from "../../models/gantt-chart-data.model";
import {IBaseDBModel} from "../../models/base-db.model";
import {CdkDragDrop, CdkDragMove, CdkDragStart, moveItemInArray} from "@angular/cdk/drag-drop";
import {Access, GanttTool} from "../../constants/enums";
import {LegsService} from "../../../services/legs.service";
import {ToastService} from "../../../services/toast.service";
import {debounceTime, interval, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {UniqueNegativeNumberGenerator} from "../../utils/negative-number-generator";
import {IGanttLeg} from "../../models/leg-gantt.model";
import {debounce} from "chart.js/helpers";
import {AirlineDesignatorTestTagPipe} from "../../pipes/airline-designator-test-tag.pipe";
import {AlertService} from "../../../services/alert.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {PermissionService} from "../../../services/permission.service";
import {IUser} from "../../models/user.model";
import {
  GanttChangesType,
  GroundTimeData,
  ICancellation,
  IRescheduleChange,
  ITailChange,
  MenuItemType
} from "../../models/gantt.models";
import {IGanttChanges} from "../../models/gantt-changes.model";


@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss']
})
export class GanttChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() registrations: IAcRegistration[];
  @Input() data: IGanttChartData;
  @Input() timespan: number;
  @Input() showLoadingSpinner?: boolean;
  @Input() acTypes: Record<number, string>;
  @Input() showUnassigned?: boolean;
  @Input() registrationChanges: ITailChange[] = [];
  @Input() rescheduleChanges: IRescheduleChange[] = [];
  @Input() cancellationChanges: ICancellation[] = [];
  @Input() selectedSectors: Record<number, boolean> = {};
  @Input() changes: IGanttChanges;
  @Input() selectedSectorsId: number[];
  @Input() selectedPairId: number;
  @Input() acRegistrationFilter?: string;
  @Input() acTypeFilter?: number;

  @Output() acRegistrationFilterChange = new EventEmitter<string>();
  @Output() acTypeFilterChange = new EventEmitter<number>();
  @Output() registrationChangesChange = new EventEmitter<ITailChange[]>();
  @Output() rescheduleChangesChange = new EventEmitter<IRescheduleChange[]>();
  @Output() cancellationChangesChange = new EventEmitter<ICancellation[]>();
  @Output() selectedSectorsChange = new EventEmitter<Record<number, boolean>>();
  @Output() refreshRequired: EventEmitter<void> = new EventEmitter();
  @Output() selectedSectorsIdChange = new EventEmitter<number[]>();
  @Output() selectedPairIdChange: EventEmitter<number> = new EventEmitter();
  @Output() openFlightInfo: EventEmitter<void> = new EventEmitter();
  @ViewChild('div2') laneList: ElementRef<HTMLDivElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('rightContainer') rightContainer: ElementRef<HTMLDivElement>;
  @ViewChildren('[data-flight]') flightElements: QueryList<HTMLDivElement>;
  filteredRegistrationsForContextMenu?: IAcRegistration[];
  currentTimeLineOffset?: number;
  ganttScale = 1;
  startDate: dayjs.Dayjs = dayjs.utc().subtract(4, 'hours').startOf('hour');
  endDate: dayjs.Dayjs = dayjs.utc().add(12, 'hours').endOf('hour');
  laneWidth = 0;
  isDragging?: boolean;
  draggingElement?: IGanttLeg;

  ganttData2: IGanttLeg[][] = [];
  virtualLegs: IGanttLeg[] = [];

  legs: Record<number, IGanttLeg> = {};

  legsInGroup: Record<number, boolean> = {};
  dateSegments: { date: Date, hours: string[] }[] = [];
  totalTimeSegments: number[];
  activeTool?: GanttTool = GanttTool.CHANGE_REGISTRATION;

  legTopOffset: Record<number, number> = {};
  laneHeightOverride: Record<number, number> = {};
  unsubscribe$ = new Subject<void>();
  uniqueIDGenerator: UniqueNegativeNumberGenerator;
  dateWidthContainer: number[] = [];
  positionData: Record<number, number> = {};
  widthData: Record<number, number> = {};
  etdWidthData: Record<number, number> = {};
  registrationSearch?: string;
  inHighlightMode = false;
  inDrawingMode = false;
  drawingTasks: Record<number, number[]> = {};
  staticTopOffset = 30;
  groupOverlayHeightOffset = 20;
  offsetPerOverlap = 85;
  misconnectionsMode = false;
  groundTimes: GroundTimeData[][] = [];
  groundTimeWidthData: Record<number, number> = {};
  groundTimePositionData: Record<number, number> = {};

  disableScroll: boolean;


  user: IUser;
  lastDrawScrollTop = 0;
  formGroup = new UntypedFormGroup({
    searchText: new UntypedFormControl(''),
  });
  hoveredLeg?: number;

  dragDateTime?: Date;
  dateTimeElement: HTMLElement;
  canDragCache: Record<number, boolean> = {};
  lockedAxis: 'x' | 'y' = 'y';
  initialX: number;
  initialY: number;

  hiddenRegistrations: Record<string, boolean> = {};
  hasHiddenRegistrations = false;

  dateFormat = 'DD/MM/YYYY';
  updateRegistrationsSubject = new Subject<void>();
  scrollTopBeforeHighlight: number;


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  recalculateGroundTimes() {
    this.groundTimes = [];
    this.groundTimePositionData = {};
    this.groundTimeWidthData = {};
    for (const legs of this.ganttData2) {
      const filteredLegs = legs.filter((leg) => !leg.isVirtual);
      if (!filteredLegs.length) {
        this.groundTimes.push([]);
        continue;
      }
      if (filteredLegs[0].operationStatus !== 'operating') {
        return;
      }
      filteredLegs.sort((a, b) => a.std > b.std ? 1 : -1);
      const newGroundTimes: GroundTimeData[] = [];
      for (let i = 0; i < filteredLegs.length - 1; i++) {
        let currentLeg = filteredLegs[i];
        let nextLeg = filteredLegs[i + 1];
        if (!nextLeg) {
          break;
        }
        if (currentLeg.arrivalStation === nextLeg.departureStation && (this.legTopOffset[currentLeg.id] || 0) === (this.legTopOffset[nextLeg.id] || 0)) {
          const existingPair = this.data[currentLeg.id].arrival?.departureLegId === nextLeg.id && this.data[currentLeg.id].leg.originalRegistrationId === currentLeg.acRegistrationId ? this.data[currentLeg.id].arrival.id : this.uniqueIDGenerator.getUniqueID();
          newGroundTimes.push({
            pairId: existingPair,
            arrivalTime: dayjs.utc(currentLeg.sta).toDate(),
            departureTime: dayjs.utc(nextLeg.std).toDate(),
            arrivalLegId: currentLeg.id,
            departureLegId: nextLeg.id,
          });
        }
      }
      this.groundTimes.push(newGroundTimes);
    }
  }

  areLegsOverlapping(leg1: ILegsModel, leg2: ILegsModel): boolean {
    if (!leg1 || !leg2) {
      return false;
    }
    return leg1.std < leg2.toa && leg1.toa > leg2.std;
  }

  constructor(private legService: LegsService, private toastService: ToastService, private cdr: ChangeDetectorRef, public alertService: AlertService, private authService: AuthService, private permissionService: PermissionService) {
    interval(60000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.currentTimeLineOffset = this.calculatePixelOffset());
    this.uniqueIDGenerator = new UniqueNegativeNumberGenerator();
    this.updateRegistrationsSubject.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe(() => this.updateRegistrationsFromFilter());
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.timespan) {
      switch (this.timespan) {
        case 12:
          this.zoomIn();
          break;
        default:
          this.zoomOut();
          break;
      }
      this.endDate = dayjs.utc().add(this.timespan ?? 12, 'hours').endOf('hour');
      this.dateSegments = [];
      this.generateDateAndHourSegments();
      this.positionData = {};
      this.widthData = {};
    }
    if (changes.data) {
      // this.updateChangesArray();

      // for (const legId in this.data) {
      //   if (this.legDataBeforeSave[+legId]) {
      //     this.data[legId].leg = {...this.legDataBeforeSave[+legId]};
      //   }
      // }
      // this.legDataBeforeSave = {};
      // this.updatePendingChangesCount();
      // this.legsInGroup = {};

      this.ganttData2 = [];
      if (this.registrations?.length) {
        this.filteredRegistrationsForContextMenu = [...this.registrations];
        for (const registration of this.registrations) {
          const legs: ILegsModel[] = [];
          for (const key in this.data ?? {}) {
            const obj = this.data[key];
            if (obj?.leg?.acRegistration !== registration?.registration) {
              continue;
            }

            if (dayjs.utc(obj.leg.tod).isAfter(this.endDate)) {
              continue;
            }
            legs.push((obj as any).leg);
            this.legs[obj.leg.id] = obj.leg;
          }
          this.ganttData2.push(legs);
        }
        if (this.showUnassigned) {
          const legs: ILegsModel[] = [];
          for (const key in this.data ?? {}) {
            const obj = this.data[key];
            if (obj.leg.acRegistrationId) {
              continue;
            }

            obj.leg.originalStd = new Date(obj.leg.std);
            obj.leg.originalSta = new Date(obj.leg.sta);

            obj.leg.toa = new Date(obj.leg.toa);
            obj.leg.tod = new Date(obj.leg.tod);
            obj.leg.std = new Date(obj.leg.std);
            if (obj.leg.etd) {
              obj.leg.etd = new Date(obj.leg.etd);
            }

            if (dayjs.utc(obj.leg.tod).isAfter(this.endDate)) {
              continue;
            }
            legs.push((obj as any).leg);
            this.legs[obj.leg.id] = obj.leg;
          }
          this.ganttData2.push(legs);
        } else {
          this.ganttData2.push([]);
        }

      }
      this.recalculateHeightOffsets();
      if (this.laneList) {
        this.laneWidth = this.laneList.nativeElement?.scrollWidth || 0;
        this.currentTimeLineOffset = this.calculatePixelOffset();
        this.widthData = {};
        this.positionData = {};
      }

      this.recalculateCanvasSize();

      this.recalculateGroundTimes();
    }

    if (changes.acTypeFilter || changes.acRegistrationFilter) {
      this.updateRegistrationsSubject.next();
    }
  }

  updateRegistrationsFromFilter() {
    this.hiddenRegistrations = {};
    if (this.acRegistrationFilter) {
      for (const registration of this.registrations) {
        if (!registration.registration.includes(this.acRegistrationFilter)) {
          this.hiddenRegistrations[registration.registration] = true;
        }
      }
    }
    if (this.acTypeFilter) {
      for (const registration of this.registrations) {
        const acType = this.acTypes[registration.acTypeId];
        if (registration.acTypeId !== this.acTypeFilter) {
          this.hiddenRegistrations[registration.registration] = true;
        }
      }
    }
    this.hasHiddenRegistrations = Object.keys(this.hiddenRegistrations).length > 0;
    this.recalculateCanvasSize(5);
  }

  ngOnInit(): void {
    this.authService.userSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.user = user;
    });
  }

  generateDateAndHourSegments(): void {
    let currentDate = this.startDate;
    this.dateWidthContainer = [];
    this.totalTimeSegments = [];
    let maxHours = 0;
    while (currentDate.isSameOrBefore(this.endDate, 'date')) {
      let hours = [];
      let startHour = currentDate.date() === this.startDate.date() ? this.startDate.hour() : 0;
      let endHour = currentDate.date() === this.endDate.date() ? this.endDate.hour() : 23;

      for (let i = startHour; i <= endHour; i++) {
        hours.push(i.toString().padStart(2, '0') + ':00');
        this.totalTimeSegments.push(0);
        maxHours++;
      }

      this.dateSegments.push({date: new Date(currentDate.toDate()), hours});


      // Move to next day
      currentDate = currentDate.add(1, 'day');
    }
    for (let i = 0; i < this.dateSegments.length; i++) {
      this.dateWidthContainer[i] = (this.dateSegments[i].hours.length / maxHours) * 100;
    }
  }


  getTaskETDWidth(task: ILegsModel): string {
    if (this.etdWidthData[task.id]) {
      return this.etdWidthData[task.id] + '%';
    }
    const taskDuration = (task.tod.getTime() - task.std.getTime()) / 3600000;
    let totalHours: number;
    totalHours = (dayjs.utc(task.toa).unix() - dayjs.utc(task.std).unix()) / 3600;

    this.etdWidthData[task.id] = (taskDuration / totalHours) * 100;
    return `${(taskDuration / totalHours) * 100}%`;
  }

  getTaskPosition(task: ILegsModel): string {
    if (this.positionData[task.id]) {
      return this.positionData[task.id] + '%';
    }
    let totalHours: number;
    let minTod: number = Infinity;
    totalHours = (this.endDate.unix() - this.startDate.unix()) / 3600;
    minTod = this.startDate.unix() * 1000;
    // const totalHours = (this.endDate.unix() - this.startDate.unix()) / 3600;
    const taskStartHour = (task.std.getTime() - (minTod)) / 3600000;
    this.positionData[task.id] = (taskStartHour / totalHours) * 100;
    return `${(taskStartHour / totalHours) * 100}%`;
  }

  getTaskWidth(task: ILegsModel): string {
    if (this.widthData[task.id]) {
      return this.widthData[task.id] + '%';
    }
    const taskDuration = (task.toa.getTime() - task.std.getTime()) / 3600000;
    let totalHours: number;
    totalHours = (this.endDate.unix() - this.startDate.unix()) / 3600;

    this.widthData[task.id] = (taskDuration / totalHours) * 100;
    return `${(taskDuration / totalHours) * 100}%`;
  }

  getGroundTimePosition(task: GroundTimeData): string {
    if (this.groundTimePositionData[task.pairId]) {
      return this.groundTimePositionData[task.pairId] + '%';
    }
    let totalHours: number;
    let minTod: number = Infinity;
    totalHours = (this.endDate.unix() - this.startDate.unix()) / 3600;
    minTod = this.startDate.unix() * 1000;
    // const totalHours = (this.endDate.unix() - this.startDate.unix()) / 3600;
    const taskStartHour = (task.arrivalTime.getTime() - (minTod)) / 3600000;
    this.groundTimePositionData[task.pairId] = (taskStartHour / totalHours) * 100;
    return `${(taskStartHour / totalHours) * 100}%`;
  }

  getGroundTimeWidth(task: GroundTimeData): string {
    if (this.groundTimeWidthData[task.pairId]) {
      return this.groundTimeWidthData[task.pairId] + '%';
    }
    const taskDuration = (task.departureTime.getTime() - task.arrivalTime.getTime()) / 3600000;
    let totalHours: number;
    totalHours = (this.endDate.unix() - this.startDate.unix()) / 3600;

    this.groundTimeWidthData[task.pairId] = (taskDuration / totalHours) * 100;
    return `${(taskDuration / totalHours) * 100}%`;
  }

  trackByObjectId(_: number, item: IBaseDBModel) {
    return item.id;
  }

  syncVerticalScroll(source: HTMLElement, target: HTMLElement): void {
    const scrollPercentage = source.scrollTop / (source.scrollHeight - source.clientHeight);
    target.scrollTop = scrollPercentage * (target.scrollHeight - target.clientHeight);
  }

  zoomIn() {
    if (this.ganttScale === 1) {
      return;
    }
    this.ganttScale++;
    this.staticTopOffset = this.ganttScale === 1 ? 30 : 15;
    this.offsetPerOverlap = this.ganttScale === 1 ? 85 : 50;
    this.groupOverlayHeightOffset = this.ganttScale === 1 ? 20 : 16;
    this.recalculateHeightOffsets();
    this.recalculateCanvasSize();
    this.laneWidth = this.laneList.nativeElement?.scrollWidth || 0;
  }

  zoomOut() {
    if (this.ganttScale === 0) {
      return;
    }
    this.ganttScale--;
    this.staticTopOffset = this.ganttScale === 1 ? 30 : 15;
    this.offsetPerOverlap = this.ganttScale === 1 ? 85 : 50;
    this.groupOverlayHeightOffset = this.ganttScale === 1 ? 20 : 16;
    this.recalculateHeightOffsets();
    this.recalculateCanvasSize();
    this.laneWidth = this.laneList.nativeElement?.scrollWidth || 0;
  }

  debouncedRedraw = debounce(this.redrawElements.bind(this), 70);
  debouncedRedrawOnScroll = debounce(this.redrawOnScroll.bind(this), 100);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.laneWidth = this.laneList.nativeElement?.scrollWidth || 0;
    this.currentTimeLineOffset = this.calculatePixelOffset();
    this.widthData = {};
    this.positionData = {};

    this.debouncedRedraw();
  }

  calculatePixelOffset(): number {
    const startTime = this.startDate.unix();
    const endTime = this.endDate.unix();
    const currentTime = dayjs.utc().unix();
    const totalDuration = endTime - startTime;
    const elapsedDuration = currentTime - startTime;
    const ratio = elapsedDuration / totalDuration;
    return Math.round(ratio * this.laneWidth);
  }

  onDragEnded() {
    this.isDragging = false;
    this.draggingElement = null;
  }


  getCurrentDragDatetime(event: CdkDragMove): Date {
    if (!this.draggingElement) {
      return;
    }
    const element = (event.source._dragRef as any)._preview;
    const boundaryElement = event.source.boundaryElement as HTMLDivElement;
    const boundaryX = boundaryElement.getBoundingClientRect().x;
    const transform = element.style.transform;
    const translateX = this.getTranslateX(transform);
    if (translateX === null) {
      return;
    }
    const totalDuration = this.endDate.unix() - this.startDate.unix();
    const relativeTranslateX = translateX - boundaryX;
    const ratio = relativeTranslateX / this.laneWidth;
    const newTime = (this.startDate.unix() * 1000) + ((totalDuration * 1000) * ratio);
    return new Date(newTime);
  }

  private getTranslateX(transform: string): number | null {
    const match = transform.match(/translate3d\((-?\d+(\.\d+)?)/);
    return match ? parseFloat(match[1]) : null;
  }

  onDragMove(event: CdkDragMove) {
    this.dragDateTime = this.getCurrentDragDatetime(event);
    if (this.activeTool === GanttTool.RESCHEDULE && this.dateTimeElement) {
      this.dateTimeElement.innerText = dayjs.utc(this.dragDateTime).format('DD/MM HH:mm');
    }
  }


  canDrag(flight: ILegsModel) {
    if (this.canDragCache[flight.id]) {
      return this.canDragCache[flight.id];
    }
    const res = !(this.activeTool === GanttTool.RESCHEDULE && (flight.etd || this.legsInGroup[flight.id] && this.findGroupFromLeg(flight).legsInGroup.some((leg) => this.data[leg]?.leg?.etd)));
    this.canDragCache[flight.id] = res;
    return res;
  }

  onDragStarted(evt: CdkDragStart, flight: ILegsModel) {
    this.isDragging = true;
    this.draggingElement = flight;
    this.laneWidth = this.laneList.nativeElement?.scrollWidth || 0;
    if (this.activeTool === GanttTool.RESCHEDULE) {
      const originalElement = evt.source.getPlaceholderElement();
      const preview: HTMLElement = (evt.source._dragRef as any)._preview;
      const stdTime = preview.querySelector('.std-time');
      const toaTime = preview.querySelector('.toa-time');
      const topInfo = preview.querySelector('.top-flight-info');
      stdTime.remove();
      toaTime.remove();
      topInfo.remove();
      preview.style.width = originalElement.offsetWidth + 'px';
      const dateTimePreviewDiv = document.createElement('div');
      dateTimePreviewDiv.classList.add('position-absolute', 'gantt-new-datetime');
      preview.insertAdjacentElement('beforeend', dateTimePreviewDiv);
      this.dateTimeElement = preview.querySelector('div.gantt-new-datetime');
    }
  }


  onMouseDown(event: MouseEvent) {

    const element = event.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    // Calculate the initial cursor position relative to the element
    this.initialX = event.clientX - rect.x;
    this.initialY = event.clientY - rect.y;
  }

  sectorClicked(flight: ILegsModel, evt: MouseEvent) {
    if (this.selectedPairId) {
      this.selectedPairId = null;
      this.selectedPairIdChange.emit(this.selectedPairId);
    }
    // if (this.activeTool !== GanttTool.SELECT) {
    //   return;
    // }
    evt.stopPropagation();
    if (!evt.ctrlKey) {
      this.selectedSectors = {}
      this.selectedSectorsId.splice(0);
      this.selectedSectorsId = [...this.selectedSectorsId];
      this.selectedSectorsIdChange.emit(this.selectedSectorsId);
      this.selectedSectorsChange.emit(this.selectedSectors);
    }
    if (this.selectedSectors[flight?.id]) {
      delete this.selectedSectors[flight?.id];
      const index = this.selectedSectorsId.findIndex((sec) => sec === flight.id);
      this.selectedSectorsId.splice(index, 1);

      this.selectedSectors = {...this.selectedSectors};
      this.selectedSectorsId = [...this.selectedSectorsId];
      this.selectedSectorsIdChange.emit(this.selectedSectorsId);
      this.selectedSectorsChange.emit(this.selectedSectors);
      return;
    }
    this.selectedSectors[flight?.id] = true;
    this.selectedSectorsId.push(flight.id);
    this.selectedSectors = {...this.selectedSectors};
    this.selectedSectorsId = [...this.selectedSectorsId];

    this.selectedSectorsChange.emit(this.selectedSectors);
    this.selectedSectorsIdChange.emit(this.selectedSectorsId);
  }


  changeTool(newTool: GanttTool) {
    this.activeTool = newTool;
    this.canDragCache = {};
    switch (newTool) {
      case GanttTool.CHANGE_REGISTRATION:
        this.lockedAxis = 'y';
        break;
      case GanttTool.RESCHEDULE:
        this.lockedAxis = 'x';
        break;
    }
  }

  groupSectors() {
    if (!this.canGroupSectors()) {
      return;
    }
    const virtualLeg: IGanttLeg = {
      isVirtual: true,
      legsInGroup: [],
    };

    for (const key in this.selectedSectors) {
      const leg = this.data[+key]?.leg;
      if (!leg) {
        continue;
      }
      virtualLeg.legsInGroup.push(leg.id);
      this.legsInGroup[leg.id] = true;
      delete this.canDragCache[leg.id];
    }
    const firstLeg = this.data[virtualLeg.legsInGroup[0]]?.leg;
    virtualLeg.acRegistrationId = firstLeg?.acRegistrationId;
    virtualLeg.acRegistration = firstLeg?.acRegistration;
    this.recalculateVirtualLegTimes(virtualLeg);
    virtualLeg.id = this.uniqueIDGenerator.getUniqueID();
    const registrationIndex = this.registrations.findIndex((reg) => reg.id === firstLeg?.acRegistrationId);
    this.ganttData2[registrationIndex].push(virtualLeg);
    this.virtualLegs.push(virtualLeg);
    this.recalculateHeightOffsets();
    this.selectedSectors = {};
    this.selectedSectorsChange.emit(this.selectedSectors);
    this.selectedSectorsId = [];
    this.selectedSectorsIdChange.emit(this.selectedSectorsId);
    this.recalculateGroundTimes();
  }

  ungroupSectors(legId?: number) {
    if (!this.canUngroupSectors(legId)) {
      return;
    }
    const selectedLegId: number = legId ?? Number(Object.keys(this.selectedSectors)?.[0]);
    const arrayIndex = this.virtualLegs.findIndex((arr) => arr.legsInGroup.includes(selectedLegId));
    if (arrayIndex === -1) {
      return;
    }
    for (const legId of this.virtualLegs[arrayIndex].legsInGroup) {
      delete this.legsInGroup[legId];
      delete this.canDragCache[legId];
    }
    const acRegistrationIndex = this.registrations.findIndex((reg) => reg.id === this.data[selectedLegId]?.leg?.acRegistrationId);
    const virtualLegIndex = this.ganttData2[acRegistrationIndex].findIndex((leg) => leg.isVirtual && leg.legsInGroup.includes(selectedLegId));
    this.ganttData2[acRegistrationIndex].splice(virtualLegIndex, 1);
    this.virtualLegs.splice(arrayIndex, 1);
    this.selectedSectors = {};
    this.selectedSectorsChange.emit(this.selectedSectors);
    this.selectedSectorsId = [];
    this.selectedSectorsIdChange.emit(this.selectedSectorsId);
    this.recalculateHeightOffsets();
    this.recalculateGroundTimes();
  }

  highlightSectors(flight?: IGanttLeg | number) {
    this.unHighlightAllSectors();
    let leg: IGanttLeg;
    if (!flight) {
      flight = +Object.keys(this.selectedSectors)?.[0];
    }
    if (!flight) {
      return;
    }
    this.scrollTopBeforeHighlight = this.rightContainer?.nativeElement?.scrollTop ?? 0;
    this.inHighlightMode = true;
    if (typeof flight === "number") {
      flight = this.data[flight]?.leg;
    }
    leg = flight;
    leg.highlighted = true;
    let registrationIndex = this.registrations.findIndex((reg) => reg.id === leg.acRegistrationId);
    if (registrationIndex !== this.registrations.length) {
      this.changeRegistrationIndex(registrationIndex, this.registrations.length - 1);
    }
    let startingIndex = this.registrations.length - 2;
    let lowestIndex = startingIndex;
    for (let i = startingIndex; i >= 0; i--) {
      const containsHighlightedLeg = this.ganttData2[i].filter((flt) => flt.departureStationId === leg.arrivalStationId && flt.tod > leg.toa);
      if (containsHighlightedLeg?.length) {
        for (const leg of containsHighlightedLeg) {
          leg.highlighted = true;
        }
        this.changeRegistrationIndex(i, lowestIndex);
        lowestIndex--;
      }
    }
    (this.rightContainer.nativeElement as HTMLDivElement).scrollTo({top: 99999999, behavior: "auto"});
    this.recalculateGroundTimes();
  }

  unHighlightAllSectors() {
    for (const key in this.data) {
      this.data[key].leg.highlighted = false;
    }
  }

  findGroupFromLeg(flight: ILegsModel) {
    const arrayIndex = this.virtualLegs.findIndex((arr) => arr.legsInGroup.includes((flight.id)));
    return this.virtualLegs[arrayIndex];
  }

  canUngroupSectors(selectedLegId?: number): boolean {
    if (!selectedLegId) {
      selectedLegId = Number(Object.keys(this.selectedSectors)?.[0]);
    }

    return !(!selectedLegId || !this.legsInGroup[selectedLegId]);
  }

  canGroupSectors(legId?: number): boolean {
    const legs = Object.keys(this.selectedSectors);
    if (legs.length < 2) {
      return false;
    }
    const registrations: Set<string> = new Set();
    for (const data of this.virtualLegs) {
      for (const legId of legs) {
        if (data.legsInGroup.includes(+legId)) {
          return false;
        }
      }
    }
    for (let i = 0; i < legs.length; i++) {
      for (let j = 0; j < legs.length; j++) {
        if (i === j) {
          continue;
        }
        const legKey = legs[i];
        const previousLegKey = legs[j];
        const leg = this.data[+legKey]?.leg;
        const previousLeg = this.data[+previousLegKey]?.leg;
        if (this.areLegsOverlapping(leg, previousLeg)) {
          return false;
        }
      }
    }
    for (const key in this.selectedSectors) {
      registrations.add(this.legs[+key].acRegistration);
    }
    return registrations.size === 1;
  }

  public readonly GanttTool = GanttTool;

  drop(event: CdkDragDrop<any, any>) {
    const flight: ILegsModel = event.item.data;
    const element = event.item.element.nativeElement;
    switch (this.activeTool) {
      case GanttTool.RESCHEDULE:
        const newTaskDate = this.dragDateTime;
        const finalDate: dayjs.Dayjs = dayjs.utc(newTaskDate).startOf('minute');
        let flightsToReschedule: IGanttLeg[] = [flight];
        let virtualLeg: IGanttLeg;
        if (this.legsInGroup[flight.id]) {
          let legs: IGanttLeg[];
          if (flight.acRegistrationId) {
            const registrationIndex = this.registrations.findIndex((registration) => registration.id === flight.acRegistrationId);
            legs = this.ganttData2[registrationIndex];
          } else {
            legs = this.ganttData2[this.ganttData2.length - 1];
          }
          virtualLeg = legs.find((leg) => leg.isVirtual && leg.legsInGroup.includes(flight.id));
          for (const legId of virtualLeg.legsInGroup) {
            if (legId !== flight.id) {
              flightsToReschedule.push(this.data[legId]?.leg);
            }
          }
        }
        const offsetMinutes: number = finalDate.diff(flight.std, 'minutes', true);
        for (const leg of flightsToReschedule) {
          if (leg.id < 0) {
            continue;
          }
          leg.std = dayjs.utc(leg.std).add(offsetMinutes, 'minutes').toDate();
          if (!this.changes.reschedule[leg.id]) {
            this.changes.reschedule[leg.id] = {oldSta: null, newSta: null, newStd: null, oldStd: null};
          }
          this.changes.reschedule[leg.id].oldStd = dayjs.utc(leg.originalStd);
          this.changes.reschedule[leg.id].newStd = dayjs.utc(leg.std);
          this.changes.reschedule[leg.id].oldSta = dayjs.utc(leg.originalSta);
          const stdStaOffset = dayjs.utc(leg.originalSta).diff(leg.originalStd, 'minutes');
          leg.sta = dayjs.utc(leg.std).add(stdStaOffset, 'minutes').toDate();
          leg.toa = leg.sta;
          this.changes.reschedule[leg.id].newSta = dayjs.utc(leg.sta);
          this.positionData[leg.id] = undefined;
          if (Math.abs(this.changes.reschedule[leg.id]?.oldStd.diff(this.changes.reschedule[leg.id].newStd)) < 60000) {
            delete this.changes.reschedule[leg.id];
          }
        }
        if (virtualLeg) {
          this.recalculateVirtualLegTimes(virtualLeg);
        }
        this.rescheduleChangesChange.emit(this.rescheduleChanges);
        break;
      case GanttTool.CHANGE_REGISTRATION:
        const originalContainer = event.previousContainer;
        const newContainer = event.container;

        const flightsForRegistrations = this.ganttData2[originalContainer.data];

        const newRegistrationArray = this.ganttData2[newContainer.data];
        if (flightsForRegistrations === newRegistrationArray) {
          element.style.left = this.getTaskPosition(flight);
          element.style.top = (this.legTopOffset[flight.id] ?? 0) + this.staticTopOffset + 'px';
          return;
        }
        let flightsToChangeACRegistration: IGanttLeg[] = [flight];
        if (this.legsInGroup[flight.id]) {
          const group = this.virtualLegs.find((grp) => grp.legsInGroup.includes(flight.id));
          for (const legId of group.legsInGroup) {
            if (legId !== flight.id) {
              flightsToChangeACRegistration.push(this.data[legId]?.leg);
            }
          }
          flightsToChangeACRegistration.push(group);
        }
        for (const leg of flightsToChangeACRegistration) {
          this.positionData[leg.id] = undefined;
          const indexOfFlight = flightsForRegistrations.findIndex((flt) => flt.id === leg.id);
          flightsForRegistrations.splice(indexOfFlight, 1);
          if (!leg.isVirtual) {
            if (!this.changes.registrationChange[leg.id]) {
              this.changes.registrationChange[leg.id] = {newRegistration: null, oldRegistration: null};
            }
            this.changes.registrationChange[leg.id].oldRegistration = this.registrations.find((reg) => reg.id === leg.originalRegistrationId);
            this.changes.registrationChange[leg.id].newRegistration = this.registrations[newContainer.data];
            leg.acRegistrationId = this.changes.registrationChange[leg.id].newRegistration?.id;
            leg.acRegistration = this.changes.registrationChange[leg.id].newRegistration?.registration;
          }

          newRegistrationArray.push(
            leg
          );

          if (leg.operationStatus !== 'operating') {
            leg.operationStatus = 'operating';
            if (this.changes.cancellations.includes(leg.id)) {
              const index = this.changes.cancellations.findIndex((flt) => flt === leg.id);
              this.changes.cancellations.splice(index, 1);
            }
          }
          if (this.changes.registrationChange[leg.id]?.newRegistration?.id === leg.originalRegistrationId || !this.changes.registrationChange[leg.id]?.newRegistration && !leg.originalRegistrationId) {
            delete this.changes.registrationChange[leg.id];
          }
        }
        this.registrationChangesChange.emit(this.registrationChanges);
        break;
    }
    element.style.left = this.getTaskPosition(flight);
    this.recalculateHeightOffsets();
    element.style.top = (this.legTopOffset[flight.id] ?? 0) + this.staticTopOffset + 'px';
    this.updateChangesArray();
    this.recalculateGroundTimes();
    if (!this.inDrawingMode) {
      return;
    }
    setTimeout(() => {
      this.redrawElements();
    }, 10);
  }

  recalculateVirtualLegTimes(virtualLeg: IGanttLeg) {
    let smallestSTD: dayjs.Dayjs = dayjs.utc().add(10, 'years');
    let latestSTA: dayjs.Dayjs = dayjs.utc().subtract(10, 'years');
    for (const legId of virtualLeg.legsInGroup) {
      const leg = this.data[legId]?.leg;
      if (!leg) {
        continue;
      }
      if (dayjs.utc(leg.sta).isAfter(latestSTA)) {
        latestSTA = dayjs.utc(leg.sta);
      }
      if (dayjs.utc(leg.std).isBefore(smallestSTD)) {
        smallestSTD = dayjs.utc(leg.std);
      }
    }
    virtualLeg.std = smallestSTD.toDate();
    virtualLeg.tod = virtualLeg.std;
    virtualLeg.sta = latestSTA.toDate();
    virtualLeg.toa = virtualLeg.sta;
    delete this.positionData[virtualLeg.id];
  }

  updateChangesArray() {
    this.rescheduleChanges = [];
    this.registrationChanges = [];
    this.cancellationChanges = [];
    for (const legId in this.changes.registrationChange) {
      if (+legId < 0) {
        continue;
      }
      const {
        arrivalStation,
        departureStation,
        flightNumber,
        std,
        airlineDesignator
      } = this.legs[+legId];
      this.registrationChanges.push({
        date: dayjs.utc(std).format('DD/MM/YYYY'),
        fromTo: departureStation + '-' + arrivalStation,
        flightNr: airlineDesignator + flightNumber,
        oldTail: this.changes.registrationChange[+legId]?.oldRegistration?.registration ?? 'None',
        newTail: this.changes.registrationChange[+legId]?.newRegistration?.registration ?? 'None'
      });
    }

    for (const legId in this.changes.reschedule) {
      if (+legId < 0) {
        continue;
      }
      const {arrivalStation, departureStation, flightNumber, airlineDesignator} = this.legs[+legId];
      // this.rescheduleChanges.push(`(${flightNumber}) ${departureStation} - ${arrivalStation}: ${this.changes.reschedule[+legId].oldStd.format('DD/MM/YYYY HH:mm')} -> ${this.changes.reschedule[+legId].newStd.format('DD/MM/YYYY HH:mm')}`);
      this.rescheduleChanges.push({
        flightNr: airlineDesignator + flightNumber,
        date: this.changes.reschedule[+legId].newStd.format('DD/MM/YYYY'),
        fromTo: departureStation + '-' + arrivalStation,
        newSta: this.changes.reschedule[+legId].newSta.format('HH:mm'),
        newStd: this.changes.reschedule[+legId].newStd.format('HH:mm'),
        oldStd: this.changes.reschedule[+legId].oldStd.format('HH:mm'),
        oldSta: this.changes.reschedule[+legId].oldSta.format('HH:mm'),
      });
    }

    for (const cancellation of this.changes.cancellations) {
      const leg = this.data?.[cancellation]?.leg;
      this.cancellationChanges.push({
        date: dayjs.utc(leg.std).format('DD/MM/YYYY'),
        fromTo: leg.departureStation + '-' + leg.arrivalStation,
        flightNr: leg.flightNumber
      });
    }

    this.registrationChangesChange.emit(this.registrationChanges);
    this.cancellationChangesChange.emit(this.cancellationChanges);
    this.rescheduleChangesChange.emit(this.rescheduleChanges);
  }

  recalculateHeightOffsets() {
    this.legTopOffset = {};
    this.laneHeightOverride = {};
    let index = 0;
    for (let group of this.ganttData2) {
      let maxOffset = 0;
      for (let i = 0; i < group.length; i++) {
        for (let j = 0; j < group.length; j++) {
          if (i === j) {
            continue;
          }
          const leg: IGanttLeg = group[i];
          const otherLeg: IGanttLeg = group[j];
          if (this.legsInGroup[leg.id]) {
            continue;
          } else if (this.legsInGroup[otherLeg.id]) {
            continue;
          }
          if (this.areLegsOverlapping(leg, otherLeg)) {
            if ((this.legTopOffset[leg.id] ?? 0) === (this.legTopOffset[otherLeg.id] ?? 0)) {
              const legOverlaps = this.getOverlapCountForLeg(leg, group);
              const otherLegLegOverlap = this.getOverlapCountForLeg(otherLeg, group);
              if (legOverlaps >= otherLegLegOverlap) {
                let group = [leg.id];
                if (leg.isVirtual) {
                  group.push(...leg.legsInGroup);
                }
                for (const legId of group) {
                  this.legTopOffset[legId] = (this.legTopOffset[legId] ?? 0) + this.offsetPerOverlap;
                }
                maxOffset = Math.max(maxOffset, this.legTopOffset[leg.id]);
              } else {
                let group = [otherLeg.id];
                if (otherLeg.isVirtual) {
                  group.push(...otherLeg.legsInGroup);
                }
                for (const legId of group) {
                  this.legTopOffset[legId] = (this.legTopOffset[legId] ?? 0) + this.offsetPerOverlap;
                }
                maxOffset = Math.max(maxOffset, this.legTopOffset[otherLeg.id]);
              }
              i = 0;
              j = 0;
            }
          }
        }
      }
      this.laneHeightOverride[index] = Math.max(this.minLaneHeight, this.minLaneHeight + maxOffset);
      index++;
    }
  }

  get minLaneHeight() {
    return this.ganttScale === 0 ? 55 : 95;
  }


  getOverlapCountForLeg(leg: ILegsModel, swimLane: ILegsModel[]): number {
    if (!leg || !swimLane) {
      return 0;
    }
    let count: number = 0;
    for (const flight of swimLane) {
      if (flight.id === leg.id) {
        continue;
      }
      if (this.areLegsOverlapping(leg, flight)) {
        count++;
      }
    }
    return count;
  }

  menuItemClicked(item: MenuItemType, flight?: IGanttLeg) {
    switch (item) {
      case this.MenuItemType.UNASSIGN_TAIL:
        if (!flight.acRegistrationId) {
          return;
        }
        const registrationIndex = this.registrations.findIndex((reg) => reg.id === flight.acRegistrationId);
        const flightsForRegistrations = this.ganttData2[registrationIndex];
        const flightsToChangeACRegistration: IGanttLeg[] = this.getFlightGroupFromLeg(flight);
        for (const leg of flightsToChangeACRegistration) {
          this.positionData[leg.id] = undefined;
          const indexOfFlight = flightsForRegistrations.findIndex((flt) => flt.id === leg.id);
          flightsForRegistrations.splice(indexOfFlight, 1);
          if (!leg.isVirtual) {
            if (!this.changes.registrationChange[leg.id]) {
              this.changes.registrationChange[leg.id] = {newRegistration: null, oldRegistration: null};
            }
            this.changes.registrationChange[leg.id].oldRegistration = this.registrations.find((reg) => reg.id === leg.originalRegistrationId);
            this.changes.registrationChange[leg.id].newRegistration = null;
            leg.acRegistrationId = this.changes.registrationChange[leg.id].newRegistration?.id;
            leg.acRegistration = this.changes.registrationChange[leg.id].newRegistration?.registration;
          }

          this.ganttData2[this.registrations.length].push(
            leg
          );

          if (this.changes.registrationChange[leg.id]?.newRegistration?.id === leg.originalRegistrationId || !this.changes.registrationChange[leg.id]?.newRegistration && !leg.originalRegistrationId) {
            delete this.changes.registrationChange[leg.id];
          }
        }

        this.updateChangesArray();
        this.recalculateGroundTimes();
        setTimeout(() => {
          this.redrawElements();
        }, 10);
        break;
      case this.MenuItemType.CANCEL_FLIGHT:
        const flightsToCancel: IGanttLeg[] = this.getFlightGroupFromLeg(flight);
        let registrationIndex2 = this.registrations.findIndex((reg) => reg.id === flight.acRegistrationId);
        if (registrationIndex2 === -1) {
          registrationIndex2 = this.registrations.length;
        }
        for (const leg of flightsToCancel) {
          const legIndex = this.ganttData2[registrationIndex2].findIndex((flight) => flight.id === leg.id);
          leg.operationStatus = "non-operating";
          leg.acRegistrationId = null;
          leg.acRegistration = null;
          this.ganttData2[registrationIndex2].splice(legIndex, 1);
          if (this.ganttData2[this.registrations.length]) {
            this.ganttData2[this.registrations.length].push(leg);
          }
          if (leg.originalOperationStatus !== leg.operationStatus && !leg.isVirtual) {
            this.changes.cancellations.push(leg.id);
            if (this.changes.registrationChange[leg.id]) {
              delete this.changes.registrationChange[leg.id];
            }
            this.updateChangesArray();

          }
        }
        this.recalculateHeightOffsets();
        this.recalculateGroundTimes();
        setTimeout(() => {
          this.redrawElements();
        }, 10);
        break;
      case this.MenuItemType.HIGHTLIGHT_MATCHES:
        this.highlightSectors(flight);
        break;
      case this.MenuItemType.MISCONNECTED_PASSENGERS:
        const misconnectedFlights = flight.misconnections;
        if (!misconnectedFlights?.length) {
          return;
        }
        this.misconnectionsMode = true;
        this.inDrawingMode = true;
        this.drawingTasks = {[flight.id]: []};
        for (const misconnectedFlight of misconnectedFlights) {
          // this.drawArrowOnCanvas(flight.id, misconnectedFlight);
          if (!this.drawingTasks[flight.id].includes(misconnectedFlight)) {
            this.drawingTasks[flight.id].push(misconnectedFlight);
          }
        }
        this.redrawElements();
        break;
      case this.MenuItemType.CONNECTED_PASSENGERS:
        const connectedFlights = flight.connections;
        if (!connectedFlights?.length) {
          return;
        }
        this.misconnectionsMode = false;
        this.inDrawingMode = true;
        this.drawingTasks = {[flight.id]: []};
        for (const connectedFlight of connectedFlights) {
          // this.drawArrowOnCanvas(flight.id, misconnectedFlight);
          if (!this.drawingTasks[flight.id].includes(connectedFlight)) {
            this.drawingTasks[flight.id].push(connectedFlight);
          }
        }
        this.redrawElements();
        break;
    }
  }

  getFlightGroupFromLeg(leg: IGanttLeg | number): IGanttLeg[] {
    if (!leg) {
      return [];
    }
    const flight: IGanttLeg = typeof leg === "number" ? this.data[leg]?.leg : leg;
    if (!this.legsInGroup[flight.id]) {
      return [flight];
    }
    let groupedLegs: IGanttLeg[] = [flight];
    if (this.legsInGroup[flight.id]) {
      const group = this.virtualLegs.find((grp) => grp.legsInGroup.includes(flight.id));
      for (const legId of group.legsInGroup) {
        if (legId !== flight.id) {
          groupedLegs.push(this.data[legId]?.leg);
        }
      }
      groupedLegs.push(group);
    }
    return groupedLegs;
  }

  public readonly MenuItemType = MenuItemType;

  setRegistration(flight: IGanttLeg, registration: IAcRegistration) {
    if (!flight || !registration) {
      return;
    }
    let registrationIndex = this.registrations.findIndex((reg) => reg.id === flight.acRegistrationId);
    if (registrationIndex === -1) {
      registrationIndex = this.registrations.length;
    }
    const flightsForRegistrations = this.ganttData2[registrationIndex];

    const newRegistrationArray = this.ganttData2[this.registrations.findIndex((reg) => reg.id === registration.id)];
    let flightsToChangeACRegistration: IGanttLeg[] = [flight];
    if (this.legsInGroup[flight.id]) {
      const group = this.virtualLegs.find((grp) => grp.legsInGroup.includes(flight.id));
      for (const legId of group.legsInGroup) {
        if (legId !== flight.id) {
          flightsToChangeACRegistration.push(this.data[legId]?.leg);
        }
      }
      flightsToChangeACRegistration.push(group);
    }
    for (const leg of flightsToChangeACRegistration) {
      this.positionData[leg.id] = undefined;
      const indexOfFlight = flightsForRegistrations.findIndex((flt) => flt.id === leg.id);
      flightsForRegistrations.splice(indexOfFlight, 1);
      if (!leg.isVirtual) {
        if (!this.changes.registrationChange[leg.id]) {
          this.changes.registrationChange[leg.id] = {newRegistration: null, oldRegistration: null};
        }
        this.changes.registrationChange[leg.id].oldRegistration = this.registrations.find((reg) => reg.id === leg.originalRegistrationId);
        this.changes.registrationChange[leg.id].newRegistration = registration;
        leg.acRegistrationId = this.changes.registrationChange[leg.id].newRegistration?.id;
        leg.acRegistration = this.changes.registrationChange[leg.id].newRegistration?.registration;
        leg.operationStatus = 'operating';
      }

      newRegistrationArray.push(
        leg
      );
      if (this.changes.registrationChange[leg.id]?.newRegistration?.id === leg.originalRegistrationId || !this.changes.registrationChange[leg.id]?.newRegistration && !leg.acRegistration) {
        delete this.changes.registrationChange[leg.id];
      }
    }

    if (this.changes.cancellations.includes(flight.id)) {
      const index = this.changes.cancellations.findIndex((flt) => flt === flight.id);
      this.changes.cancellations.splice(index, 1);
    }

    this.recalculateHeightOffsets();
    this.updateChangesArray();
    this.recalculateGroundTimes();

  }

  onRegistrationSearch() {
    this.filteredRegistrationsForContextMenu = this.registrations.filter((reg) => {
      if (!this.registrationSearch) {
        return true;
      }
      if (reg.registration?.toLowerCase().includes(this.registrationSearch.toLowerCase())) {
        return true;
      }
      return this.acTypes[reg.acTypeId]?.toLowerCase()?.includes(this.registrationSearch?.toLowerCase());
    });
  }

  contextMenuClosed() {
    this.registrationSearch = null;
    this.filteredRegistrationsForContextMenu = [...this.registrations];
    this.disableScroll = false;
  }

  // Unused at the time, might need it later
  swapRegistrationIndexes(oldIndex: number, newIndex: number) {
    this.swapArray(this.registrations, oldIndex, newIndex);
    this.swapArray(this.ganttData2, oldIndex, newIndex);


    const oldIndexHeight = this.laneHeightOverride[oldIndex];
    this.laneHeightOverride[oldIndex] = this.laneHeightOverride[newIndex];
    this.laneHeightOverride[newIndex] = oldIndexHeight;
  }

  changeRegistrationIndex(fromIndex: number, toIndex: number) {
    moveItemInArray(this.registrations, fromIndex, toIndex);
    moveItemInArray(this.ganttData2, fromIndex, toIndex);
    // Get the range of affected indexes
    const newIndexObject = {};

    // Temporary array to hold the values from the indexObject
    const tempValues = [];

    // Extract values from the indexObject
    this.registrations.forEach((item, index) => {
      tempValues[index] = this.laneHeightOverride[index];
    });

    // Move the value in the tempValues array to reflect the new position
    const movedValue = tempValues.splice(fromIndex, 1)[0];
    tempValues.splice(toIndex, 0, movedValue);

    // Update the newIndexObject with the correct indexes
    tempValues.forEach((value, index) => {
      newIndexObject[index] = value;
    });


    this.laneHeightOverride = newIndexObject;
  }

  // Unused for now, might need it later
  swapArray(Array: any, Swap1: number, Swap2: number): any {
    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2]
    Array[Swap2] = temp
    return Array;
  }

  clearCanvas() {
    const ctx = this.canvas?.nativeElement?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    }
  }

  onLaneClick() {
    if (this.inDrawingMode) {
      this.inDrawingMode = false;
      this.clearCanvas();
    }
    const flightId = this.selectedSectorsId[0];
    this.selectedPairId = null;
    this.selectedSectors = {}
    this.selectedSectorsId.splice(0);
    this.selectedSectorsId = [...this.selectedSectorsId];
    this.selectedSectorsIdChange.emit(this.selectedSectorsId);
    this.selectedSectorsChange.emit(this.selectedSectors);
    this.selectedPairIdChange.emit(this.selectedPairId);
    if (!this.inHighlightMode) {
      return;
    }
    this.inHighlightMode = false;
    this.unHighlightAllSectors();
    this.registrations.sort((a, b) => a.id > b.id ? 1 : a.id - b.id);

    this.ganttData2 = [];
    const unassigned = [];
    for (const registration of this.registrations) {
      const legs: ILegsModel[] = [];
      for (const key in this.data ?? {}) {
        const obj = this.data[key];
        if (!obj.leg.acRegistrationId) {
          unassigned.push(obj.leg);
          continue;
        }
        if (obj.leg.acRegistration !== registration.registration) {
          continue;
        }
        legs.push((obj as any).leg);
      }
      this.ganttData2.push(legs);
    }
    this.ganttData2.push(unassigned);
    this.rightContainer.nativeElement.scrollTo({top: this.scrollTopBeforeHighlight});
    this.recalculateGroundTimes();
  }


  drawArrowOnCanvas(task1Id: number, task2Id: number) {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    const connectionRight = document.querySelector(`div[data-flight="${task1Id}"] .right-anchor`);
    const connectionLeft = document.querySelector(`div[data-flight="${task2Id}"] .left-anchor`);

    if (!connectionRight || !connectionLeft) {
      return;
    }

    const rightRect = connectionRight.getBoundingClientRect();
    const leftRect = connectionLeft.getBoundingClientRect();

    const startX = rightRect.left + this.rightContainer.nativeElement.scrollLeft - 168;
    const startY = rightRect.top + rightRect.height / 2 + this.rightContainer.nativeElement.scrollTop - 160;
    const endX = leftRect.left + this.rightContainer.nativeElement.scrollLeft - 168;
    const endY = leftRect.top + leftRect.height / 2 + this.rightContainer.nativeElement.scrollTop - 160;


    // Set line style
    const style = this.misconnectionsMode ? '#ED7D31' : '#4472C4';
    ctx.strokeStyle = style;
    ctx.lineWidth = 1;

    // Draw line

    ctx.beginPath();
    ctx.arc(startX, startY, 4, 0, 2 * Math.PI);
    ctx.fillStyle = style;
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();

    // Draw arrowhead
    this.drawArrowhead(ctx, endX, endY, startX, startY);
  }

  drawArrowhead(ctx: CanvasRenderingContext2D, x: number, y: number, fromX: number, fromY: number) {
    const headLength = 10; // Length of arrowhead
    const angle = Math.atan2(y - fromY, x - fromX);

    const style = this.misconnectionsMode ? '#ED7D31' : '#4472C4';
    ctx.strokeStyle = style;
    ctx.fillStyle = style;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - headLength * Math.cos(angle - Math.PI / 6), y - headLength * Math.sin(angle - Math.PI / 6));
    ctx.lineTo(x - headLength * Math.cos(angle + Math.PI / 6), y - headLength * Math.sin(angle + Math.PI / 6));
    ctx.lineTo(x, y);
    ctx.lineTo(x - headLength * Math.cos(angle - Math.PI / 6), y - headLength * Math.sin(angle - Math.PI / 6));
    ctx.stroke();
    ctx.fill();
  }

  redrawOnScroll() {
    if (!this.inDrawingMode) {
      return;
    }
    if (!this.canvas?.nativeElement) {
      return;
    }
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (!ctx) {
      return;
    }
    let shouldRedraw = false;
    for (const taskId in this.drawingTasks) {
      let areTargetsInUnassignedRow = false;
      for (const targetFlightId of this.drawingTasks[taskId]) {
        if (this.changes.cancellations.includes(targetFlightId) || !this.legs[targetFlightId]?.acRegistrationId) {
          areTargetsInUnassignedRow = true;
          break;
        }
      }
      if ((!this.legs[+taskId]?.acRegistrationId || areTargetsInUnassignedRow) && this.lastDrawScrollTop !== this.rightContainer.nativeElement.scrollTop) {
        shouldRedraw = true;
        break;
      }
    }
    this.lastDrawScrollTop = this.rightContainer.nativeElement.scrollTop;
    if (!shouldRedraw) {
      return;
    }
    this.debouncedRedraw();
  }


  redrawElements() {
    if (!this.inDrawingMode) {
      return;
    }
    this.clearCanvas();
    for (const taskId in this.drawingTasks) {
      for (const targetFlightId of this.drawingTasks[taskId]) {
        this.drawArrowOnCanvas(+taskId, targetFlightId);
      }
    }
  }


  onTaskHover(id: number) {
    this.hoveredLeg = id;
  }

  onTaskLeave(id: number) {
    this.hoveredLeg = null;
  }


  protected readonly Access = Access;
  protected readonly AirlineDesignatorTestTagPipe = AirlineDesignatorTestTagPipe;

  undoChanges(changesType: GanttChangesType) {
    switch (changesType) {
      case GanttChangesType.RESCHEDULE:
        for (const legId in this.changes.reschedule) {
          this.legs[+legId].std = this.changes.reschedule[+legId].oldStd.toDate();
          this.legs[+legId].tod = this.changes.reschedule[+legId].oldStd.toDate();
          this.legs[+legId].sta = this.changes.reschedule[+legId].oldSta.toDate();
          this.legs[+legId].toa = this.changes.reschedule[+legId].oldSta.toDate();
          delete this.positionData[+legId];
          if (this.legsInGroup[+legId]) {
            const virtualLeg = this.virtualLegs.find((leg) => leg.legsInGroup.includes(+legId));
            if (!virtualLeg) {
              continue;
            }
            this.recalculateVirtualLegTimes(virtualLeg);
            delete this.positionData[virtualLeg.id];
            delete this.widthData[virtualLeg.id];
          }
        }
        this.rescheduleChanges = [];
        this.changes.reschedule = {};
        this.rescheduleChangesChange.emit(this.rescheduleChanges);
        break;
      case GanttChangesType.CHANGE_REGISTRATION:
        for (const legId in this.changes.registrationChange) {
          this.setRegistration(this.legs[+legId], this.changes.registrationChange[+legId]?.oldRegistration);
          delete this.positionData[+legId];
          if (this.legsInGroup[+legId]) {
            const virtualLeg = this.virtualLegs.find((leg) => leg.legsInGroup.includes(+legId));
            if (!virtualLeg) {
              continue;
            }
            this.recalculateVirtualLegTimes(virtualLeg);
          }
        }
        this.registrationChanges = [];
        this.changes.registrationChange = {};
        this.registrationChangesChange.emit(this.registrationChanges);
        break;
      case GanttChangesType.CANCELLATION:
        while (this.changes.cancellations.length > 0) {
          const legId = this.changes.cancellations[0];
          const leg = this.legs[legId];
          if (this.legs[legId]?.originalRegistrationId) {
            this.setRegistration(this.legs[legId], this.registrations.find((reg) => reg.id === this.legs[legId].originalRegistrationId));
          }

          delete this.positionData[legId];
          if (this.legsInGroup[legId]) {
            const virtualLeg = this.virtualLegs.find((leg) => leg.legsInGroup.includes(legId));
            if (!virtualLeg) {
              continue;
            }
            this.recalculateVirtualLegTimes(virtualLeg);
          }
        }
        this.changes.cancellations = [];
        this.cancellationChanges = [];
        this.cancellationChangesChange.emit(this.cancellationChanges);
        break;
    }
    this.recalculateHeightOffsets();
    this.recalculateGroundTimes();
    if (this.inDrawingMode) {
      setTimeout(() => {
        this.redrawElements();
      }, 10);

    }
  }


  onRegistrationDrop(evt: CdkDragDrop<any>) {
    const filteredRegistrations = this.registrations.filter((reg) => !this.hiddenRegistrations[reg.registration]);
    const {previousIndex, currentIndex} = evt;
    const previousIndexAtRealTable = this.registrations.findIndex((reg) => reg.id === filteredRegistrations[previousIndex].id);
    const currentIndexAtRealTable = this.registrations.findIndex((reg) => reg.id === filteredRegistrations[currentIndex].id);
    moveItemInArray(this.registrations, previousIndexAtRealTable, currentIndexAtRealTable);
    moveItemInArray(this.ganttData2, previousIndexAtRealTable, currentIndexAtRealTable);
    moveItemInArray(this.groundTimes, previousIndexAtRealTable, currentIndexAtRealTable);
  }

  groundTimeClicked(groundTimes: GroundTimeData, evt: MouseEvent) {
    evt.stopPropagation();
    this.selectedPairId = groundTimes.pairId;
    this.selectedPairIdChange.emit(this.selectedPairId);

    this.selectedSectors = {};
    this.selectedSectorsId = [];
    this.selectedSectorsChange.emit(this.selectedSectors);
    this.selectedSectorsIdChange.emit(this.selectedSectorsId)
  }

  hideRegistration(registration: string) {
    this.hiddenRegistrations[registration] = true;
    this.hasHiddenRegistrations = true;
    this.recalculateCanvasSize(5);
  }

  recalculateCanvasSize(delay: number = 1) {
    setTimeout(() => {
      const canvas = this.canvas?.nativeElement;
      const container = this.laneList?.nativeElement as HTMLDivElement;

      if (canvas && container) {
        canvas.width = container.scrollWidth;
        canvas.style.width = container.scrollWidth + 'px';
        canvas.height = container.scrollHeight;
        canvas.style.height = container.scrollHeight + 'px';
      }
    }, delay);
  }

  showAllRegistrations() {
    this.hiddenRegistrations = {}
    this.hasHiddenRegistrations = false;
    this.acTypeFilter = null;
    this.acRegistrationFilter = null;
    this.acTypeFilterChange.emit(this.acTypeFilter);
    this.acRegistrationFilterChange.emit(this.acRegistrationFilter);
    this.recalculateCanvasSize(5);
  }

  onDoubleClick(flight: ILegsModel, evt: MouseEvent) {
    this.sectorClicked(flight, evt);
    this.openFlightInfo.emit();
  }

  contextMenuOpened() {
    this.disableScroll = true;
  }
}
