import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IGanttLeg} from "../../../../../shared/models/leg-gantt.model";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import {IDelayCode} from "../../../../../shared/models/delay-code.model";
import {
  calculateETA,
  dayjsToNgbDate,
  extractPassengersPerCategory,
  extractSeatingConfigurations,
  getLatestLegTime,
  getTimestamp,
  minutesToHHmm,
  ngbDateToDayjs,
  ngbDateToFormat
} from "../../../../../shared/utils/utils";
import * as dayjs from "dayjs";
import {MinutesFromTimePipe} from "../../../../../shared/pipes/minutes-from-time.pipe";
import {
  FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder
} from "../../../../../shared/forms/formBuilders/flights/flights-change-flight-details-arrival-departure-flight-form-builder";
import {TimeFromMinutesPipe} from "../../../../../shared/pipes/time-from-minutes.pipe";
import {ISimpleData} from "../../../../../shared/models/simpleData.model";
import {IPassengerCategory} from "../../../../../shared/models/passenger-categories.model";
import {takeUntil} from "rxjs/operators";
import {IGenericContainerObject} from "../../../../../shared/models/genericContainerObject.model";
import {ILegsModel} from "../../../../../shared/models/legs.model";
import {debounceTime, firstValueFrom, Observable, Subject} from "rxjs";
import {ILegDelaysLogBulkModel, ILegDelaysLogModel} from "../../../../../shared/models/leg-delays-log.model";
import {ITransferFile} from "../../../../../shared/models/transfer-file.model";
import {DiversionDialogComponent} from "../../../../../shared/components/diversion-dialog/diversion-dialog.component";
import {Access, PermissionUIMasks} from "../../../../../shared/constants/enums";
import {LegsService} from "../../../../../services/legs.service";
import {ToastService} from "../../../../../services/toast.service";
import {LegDelaysLogService} from "../../../../../services/leg-delays-log.service";
import {TimeTypesService} from "../../../../../services/time-types.service";
import {TransferFileService} from "../../../../../services/transfer-file.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITimeTypesModel} from "../../../../../shared/models/time-types.model";
import {StaticUserGroupConstants} from "../../../../../shared/constants/static-user-group.constants";
import {PermissionService} from "../../../../../services/permission.service";
import {ConnectingPassengersService} from "../../../../../services/connecting-passengers.service";
import {AuthService} from "../../../../../services/auth.service";
import {GeneralSettingsService} from "../../../../../services/general-settings.service";
import {IWeatherMetarModel} from "../../../../../shared/models/weather-metar.model";
import {IWeatherTafModel} from "../../../../../shared/models/weather-taf.model";
import {
  ReturnToRampAirborneDialogComponent
} from "../../../../../shared/components/return-to-ramp-airborne-dialog/return-to-ramp-airborne-dialog.component";
import {IAcRegistration} from "../../../../../shared/models/ac-registration.model";

@Component({
  selector: 'app-gantt-flight-info',
  templateUrl: './gantt-flight-info.component.html',
  styleUrls: ['./gantt-flight-info.component.scss']
})
export class GanttFlightInfoComponent implements OnInit, OnDestroy, OnChanges {
  @Input() leg!: IGanttLeg;
  @Input() delayCodes: IDelayCode[];
  @Input() delayCodesKV: Record<number, IDelayCode>;
  @Input() passengerClasses: ISimpleData[];
  @Input() passengerCategories: IPassengerCategory[];
  @Input() metarData: { [iata: string]: IWeatherMetarModel };
  @Input() tafData: { [iata: string]: IWeatherTafModel };
  @Input() registrations: IAcRegistration[];
  isSaving: boolean;
  dateFormat = 'DD/MM/YYYY';
  realTimeInfoOpenTab: string;
  maxArrivalDelayMinutes = 0;
  maxDepartureDelayMinutes = 0;
  currentArrivalDelayMinutes = 0;
  currentDepartureDelayMinutes = 0;
  form: UntypedFormGroup;
  calculateDelaySubject = new Subject<void>();
  calculate$ = this.calculateDelaySubject.pipe(debounceTime(100));
  passengerData: { [key: string]: { passengers: number; bags: string, time: string } };
  unsubscribe$ = new Subject<void>();

  paxContainer: IGenericContainerObject<ISimpleData> = {};
  seatingConfigurations: IGenericContainerObject<string> = {};
  formRestoreValue: any;
  arrivalAccess: Access;
  departureAccess: Access;
  delaysToBeSaved?: ILegDelaysLogBulkModel;
  isBusy?: boolean;
  timeTypes: IGenericContainerObject<ITimeTypesModel> = {};
  disabled = false;
  interactingStations?: string[];

  @Output() saveComplete = new EventEmitter<void>();
  @Output() saveInProgress = new EventEmitter<boolean>();

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get passengerDataLength() {
    return Object.keys(this.passengerData ?? {})?.length ?? 0;
  }


  constructor(private minutesFromTimePipe: MinutesFromTimePipe, private fb: FormBuilder, private timeFromMinutesPipe: TimeFromMinutesPipe, private legService: LegsService,
              private toastService: ToastService,
              private legDelayService: LegDelaysLogService,
              private timeTypesService: TimeTypesService,
              private transferFileService: TransferFileService,
              private modalService: NgbModal,
              private permissionService: PermissionService,
              private connectingPassengersService: ConnectingPassengersService,
              private authService: AuthService,
              private generalSettingsService: GeneralSettingsService,) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.leg && this.leg) {
      this.interactingStations = [this.leg.arrivalStation, this.leg.departureStation];
      Promise.all([this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_FLIGHTS_DEPARTING_INFORMATION, this.leg?.departureStationId), this.permissionService.getPermissionAccess(PermissionUIMasks.WEB_FLIGHTS_ARRIVING_INFORMATION, this.leg?.arrivalStationId)]).then(([departureAccess, arrivalAccess]) => {
        this.departureAccess = departureAccess;
        this.arrivalAccess = arrivalAccess;
        if (!this.arrivalAccess && !this.departureAccess) {
          this.form = FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder.constructForm(
            this.fb,
            this.timeFromMinutesPipe,
            this.passengerClasses,
            this.passengerCategories
          );
          return;
        }
        this.generalSettingsService.getPassengerCategories().subscribe((results) => {
          this.passengerCategories = results;
          if (!this.form) {
            this.prepareForm();
          }
        });
        if (this.delayCodes?.length) {
          for (const delayCode of this.delayCodes) {
            this.delayCodesKV[delayCode.id] = delayCode;
          }
        }
        this.calculate$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
          this.calculateMaxCurrentDelays();
        });
        const station = this.authService.user.location;
        if (this.authService.user.userGroup === StaticUserGroupConstants.STR_TO_ID.DUTY_MANAGER) {
          this.disabled = station !== this.leg.departureStation;
        }
        this.generalSettingsService.generalSettings.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
          this.dateFormat = settings?.dateFormat || 'DD/MM/YYYY';
        });
        if (this.leg && this.passengerClasses && this.passengerCategories) {
          this.prepareForm();
        }
        this.getConnectingPassengers();

      });
    }
  }

  async getConnectingPassengers() {
    this.passengerData = await firstValueFrom(this.connectingPassengersService.getconnectingPassengers({parentLegId: this.leg.id}));
  }

  prepareForm() {
    this.createForm();
    this.form.get('atdDate').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
      this.localCalculateETA();
    });
    this.form.get('etdDate').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.localCalculateETA();
    });
    this.form.get('etdTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.localCalculateETA();
    });
    this.form.get('totTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.localCalculateETA();
    });
    this.form.get('niTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.localCalculateETA();
    });
    this.form.get('atdTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
      this.localCalculateETA();
    });
    this.form.get('etdDate').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('etdTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('ataTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.actualTimeChanged(true);
      this.calculateDelaySubject.next();
    });
    const seatConfigs = extractSeatingConfigurations(this.leg.seatingConfiguration);

    seatConfigs.forEach((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);
      this.seatingConfigurations[code] = amount;
    });
    this.paxContainer = this.calcPaxData(this.leg ? this.leg.pax : null);
    console.log('LEG PAX:', this.leg.pax);
    this.patchForm();
    this.formRestoreValue = this.form?.getRawValue();
    this.form.updateValueAndValidity();
    if (this.departureAccess !== Access.RW) {
      this.disableDepartureFormFields();
    }
    if (this.arrivalAccess !== Access.RW) {
      this.disableArrivalFormFields();
    }
  }

  createForm() {
    if (!this.leg || !this.passengerClasses) {
      return;
    }
    this.form = FlightsChangeFlightDetailsArrivalDepartureFlightFormBuilder.constructForm(
      this.fb,
      this.timeFromMinutesPipe,
      this.passengerClasses,
      this.passengerCategories
    );

    this.form.get('etdDate').enable();
    this.form.get('etdTime').enable();
    this.form.get('ctotTime').enable();

    this.paxContainer = this.calcPaxData(this.leg ? this.leg.pax : null);
  }

  calcPaxData(seatConfig: string | null | undefined): IGenericContainerObject<ISimpleData> {

    const paxContainer: IGenericContainerObject<ISimpleData> = {};
    if (!seatConfig) {
      return paxContainer;
    }

    const passClasses = this.passengerClasses ? this.passengerClasses : [];

    const seatConfigs = extractSeatingConfigurations(seatConfig);

    seatConfigs.forEach((item: string) => {
      const code = item.slice(0, 1);
      const amount = item.slice(1);

      const title = passClasses.find((cls) => cls.code === code)?.code;

      if (title) {
        paxContainer[code] = {code, description: amount};
      }
    });

    return paxContainer;
  }

  patchForm() {
    if (!this.leg) {
      return;
    }

    if (this.departureAccess) {
      this.patchFormDepartureInfo();
    }
    if (this.arrivalAccess) {
      this.patchFormArrivalInfo();
    }

    if (!this.formRestoreValue) {
      this.formRestoreValue = this.form?.getRawValue();
    }
    if (!this.leg.eta) {
      this.localCalculateETA();
    }
  }

  localCalculateETA() {
    const eta = calculateETA(this.leg, this.form.getRawValue().atdDate, this.form.getRawValue().atdTime ?? null, this.form.getRawValue().totTime ?? null, (this.form.getRawValue().etdTime && this.form.getRawValue().etdDate) ? dayjs.utc(ngbDateToFormat(this.form.getRawValue().etdDate) + '-' + (this.form.getRawValue().etdTime ?? '00:00'), 'DD.MM.YYYY-HH:mm').toDate() : this.leg?.etd ? dayjs(this.leg.etd).toDate() : null);
    if (eta && (this.form.get('atdTime').value || this.form.get('etdTime').value)) {
      this.form.get('etaTime').setValue(eta);
    } else {
      this.form.get('etaTime').setValue(null);
    }
  }

  restoreForm() {
    this.createForm();
    this.form.get('atdDate').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
    });
    this.form.get('atdTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.actualTimeChanged(false);
      this.calculateDelaySubject.next();
    });
    this.form.get('etdDate').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('etdTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.calculateDelaySubject.next());
    this.form.get('ataTime').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.actualTimeChanged(true);
      this.calculateDelaySubject.next();
    });

    this.form?.patchValue(this.formRestoreValue);
    this.calculateDelaySubject.next();
  }

  async saveForm() {
    if (this.form?.invalid || this.maxDepartureDelayMinutes > 0 && this.currentDepartureDelayMinutes > this.maxDepartureDelayMinutes || this.maxArrivalDelayMinutes > 0 && this.currentArrivalDelayMinutes !== this.maxArrivalDelayMinutes) {
      console.log('form is invalid');
      return;
    }
    this.isBusy = true;
    this.saveInProgress.emit(true);

    const newLeg: ILegsModel | undefined = this.generateLegFromForm(this.form);

    if (!newLeg) {
      console.log('new leg is empty');
      this.isBusy = false;
      this.saveInProgress.emit(false);
      return;
    }


    const timeTypeResult = await firstValueFrom(this.timeTypesService.getTimeTypes());
    if (!timeTypeResult.length) {
      this.toastService.showError('Could not get time types from the database. Please try again.');
      return;
    }
    for (const timeType of timeTypeResult) {
      this.timeTypes[timeType.identifier] = timeType;
    }

    if (newLeg.eta && dayjs(newLeg.eta).isBefore(dayjs(getLatestLegTime(newLeg, false)))) {
      newLeg.eta = dayjs(newLeg.eta).add(1, 'day').toDate();
    }

    if (newLeg.ata && newLeg.atd && dayjs(newLeg.ata).isBefore(newLeg.atd)) {
      newLeg.ata = dayjs(newLeg.ata).add(1, 'day').toDate();
    }


    const result = await firstValueFrom(this.legService.saveLeg(newLeg));
    if (result) {
      this.toastService.showSuccess("The leg details have been saved");
    }
    this.saveDelays(result).subscribe((result) => {
      if (result) {
        this.createMVT(newLeg);
        this.toastService.showSuccess("Leg delays have been saved");
      }
      this.isBusy = false;
      this.saveInProgress.emit(false);
      this.saveComplete.emit();
    });
  }

  saveDelays(leg: ILegsModel): Observable<ILegDelaysLogBulkModel> {
    const objToSend: ILegDelaysLogBulkModel = {
      legId: leg.id,
      arrivalDelays: [],
      departureDelays: [],
    };
    for (const key in this.form.getRawValue().arrivalDelaysNew) {
      const val = this.form.getRawValue().arrivalDelaysNew[key];
      if (val.time && val.delayCode) {
        objToSend.arrivalDelays.push({
          delayCodeId: Number(val.delayCode),
          minutes: this.minutesFromTimePipe.transform(val.time),
        });
      }
    }
    for (const key in this.form.getRawValue().departureDelaysNew) {
      const val = this.form.getRawValue().departureDelaysNew[key];
      if (val.time && val.delayCode) {
        objToSend.departureDelays.push({
          delayCodeId: Number(val.delayCode),
          minutes: this.minutesFromTimePipe.transform(val.time),
        });
      }
    }
    this.delaysToBeSaved = objToSend;
    return this.legDelayService.saveLegDelayLogBulk(objToSend);
  }

  async createMVT(leg: ILegsModel) {
    const lineSeperator = '\n'
    let contentOfAD: string;
    let contentOfAA: string;
    let delaysText = '';
    let paxAmount = 0;
    console.log('Old atd : ', this.leg.atd);
    console.log('New atd : ', leg.atd);

    if (!dayjs(this.leg.atd).isSame(dayjs(leg.atd)) && leg.takeOffTime !== null) {
      //Check for delays
      console.log('THE delays are : ', this.delaysToBeSaved)
      if (this.delaysToBeSaved.departureDelays.length) {
        let delayCodeDep = '';
        let delayMinutesDep = ''
        for (const delay of this.delaysToBeSaved.departureDelays) {
          delayCodeDep = delayCodeDep + this.delayCodes.find((dl) => dl.id === delay.delayCodeId).code + '/';
          delayMinutesDep = delayMinutesDep + minutesToHHmm(+delay.minutes) + '/';
        }
        delayMinutesDep = delayMinutesDep.slice(0, delayMinutesDep.length - 1) //remove the '/' in the end
        delaysText = 'DL' + delayCodeDep + delayMinutesDep;
      }

      //check for Pax
      if (leg.pax) {
        const paxOfLegDep = extractSeatingConfigurations(leg.pax);
        for (const pax of paxOfLegDep) {
          paxAmount = +pax.slice(1) + paxAmount;
        }
      }
      contentOfAD = `MVT${lineSeperator}${(leg.airlineDesignator) + (leg.flightNumber)}/${dayjs(leg.std).format('DD').toUpperCase()}.${leg.acRegistration}.${leg.departureStation}${lineSeperator}AD${dayjs(leg.atd).utc().format('DD').toUpperCase()}${dayjs(leg.atd).utc().format('HHmm').toUpperCase()}/${dayjs(leg.takeOffTime).utc().format('DD').toUpperCase()}${dayjs(leg.takeOffTime).utc().format('HHmm').toUpperCase()} EA${dayjs(leg.eta).utc().format('HHmm').toUpperCase()} ${leg.arrivalStation}${lineSeperator}${delaysText !== '' ? delaysText + lineSeperator : ''}${paxAmount > 0 ? 'PX' + String(paxAmount) : ''}`;
      const nameOfAD = 'AD' + dayjs(leg.atd).format('DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' + 'OUT' + '.txt'
      const file: ITransferFile = {fileName: nameOfAD, fileContent: contentOfAD}
      this.transferFileService.sendFile(file).subscribe();
    }

    if (!dayjs(this.leg.landingTime).isSame(dayjs(leg.landingTime)) && leg.ata !== null && leg.landingTime !== null) {
      contentOfAA = `MVT${lineSeperator}${(leg.airlineDesignator) + (leg.flightNumber)}/${dayjs(leg.std).utc().format('DD').toUpperCase()}.${leg.acRegistration}.${leg.departureStation}${lineSeperator}AA${dayjs(leg.ata).utc().format('DD').toUpperCase()}${dayjs(leg.ata).utc().format('HHmm').toUpperCase()}/${dayjs(leg.landingTime).utc().format('DD').toUpperCase()}${dayjs(leg.landingTime).utc().format('HHmm').toUpperCase()}`;
      const nameOfAA = 'AA' + dayjs(leg.ata).format('DDMMMYY').toUpperCase() + '__##__' + 'MVT_SFTP' + '_' + getTimestamp() + '_' + 'OUT' + '.txt'
      const file: ITransferFile = {fileName: nameOfAA, fileContent: contentOfAA}
      this.transferFileService.sendFile(file).subscribe();
    }
  }

  divertFlight(leg: ILegsModel) {
    const modalRef = this.modalService.open(DiversionDialogComponent, {size: 'xl'});
    const modal = modalRef.componentInstance as DiversionDialogComponent;
    modal.title = 'Diversion';
    modal.leg = leg
  }

  private generateLegFromForm(form: UntypedFormGroup | undefined): ILegsModel | undefined {
    if (!form) {
      return undefined;
    }

    const formValues = this.form?.getRawValue();

    const newLeg: IGanttLeg = {...this.leg};
    newLeg.acRegistrationId = newLeg.originalRegistrationId;
    const registration = this.registrations?.find((reg) => reg.id === newLeg.acRegistrationId);
    newLeg.acRegistration = registration?.registration;
    newLeg.std = newLeg.originalStd;
    newLeg.sta = newLeg.originalSta;
    newLeg.operationStatus = newLeg.originalOperationStatus;

    let lastGivenDateTimeMoment = newLeg.atd ? dayjs.utc(newLeg.atd) : newLeg.etd ? dayjs.utc(newLeg.etd) : dayjs.utc(newLeg.std);

    if (!lastGivenDateTimeMoment || !lastGivenDateTimeMoment.isValid()) {
      console.log('last given departure datetime is invalid. aborting saving process here!');
      return undefined;
    }

    if (formValues.etdDate && formValues.etdTime) {
      newLeg.etd = dayjs.utc(ngbDateToFormat(formValues.etdDate) + '-' + (formValues?.etdTime ?? '00:00'), 'DD.MM.YYYY-HH:mm').toDate();
    }

    if (formValues.atdDate && formValues.atdTime) {
      const atdMoment = dayjs.utc(ngbDateToFormat(formValues.atdDate) + '-' + (formValues?.atdTime ?? '00:00'), 'DD.MM.YYYY-HH:mm');
      newLeg.atd = atdMoment.toDate();
      lastGivenDateTimeMoment = atdMoment;
    } else {
      newLeg.atd = null;
    }

    if (formValues.totTime) {
      newLeg.takeOffTime = this.calcApplicableButPossibleNextDayDateTime(formValues.totTime, lastGivenDateTimeMoment).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.takeOffTime);
    } else {
      newLeg.takeOffTime = null;
    }

    if (formValues.etaTime) {
      newLeg.eta = this.calcApplicableButPossibleNextDayDateTime(formValues.etaTime, lastGivenDateTimeMoment).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.eta);
    } else {
      newLeg.eta = null;
    }

    if (formValues.lndTime) {
      const atdTime: string = this.form.getRawValue().atdTime?.split(':');
      let atdDate = ngbDateToDayjs(this.form.getRawValue().atdDate);
      if (atdTime?.length === 2 && atdDate.isValid()) {
        atdDate = atdDate.hour(+atdTime[0]).minute(+atdTime[1]);
      } else {
        atdDate = dayjs.utc();
      }
      newLeg.landingTime = this.calcApplicableButPossibleNextDayDateTime(formValues.lndTime, atdDate).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.landingTime);
    } else {
      newLeg.landingTime = null;
    }

    if (formValues.ataTime) {
      const atdTime: string = this.form.getRawValue().atdTime?.split(':');
      let atdDate = ngbDateToDayjs(this.form.getRawValue().atdDate);
      if (atdTime?.length === 2 && atdDate.isValid()) {
        atdDate = atdDate.hour(+atdTime[0]).minute(+atdTime[1]);
      } else {
        //atdDate = dayjs.utc();
        atdDate = dayjs.utc(this.leg.tod);
      }
      newLeg.ata = this.calcApplicableButPossibleNextDayDateTime(formValues.ataTime, atdDate).toDate();
      lastGivenDateTimeMoment = dayjs.utc(newLeg.ata);
    } else {
      newLeg.ata = null;
    }

    if (formValues.ctotTime) {
      newLeg.ctot = this.calcApplicableButPossibleNextDayDateTime(formValues.ctotTime, lastGivenDateTimeMoment).toDate();
    }

    if (formValues.niTime) {
      newLeg.ni = this.calcApplicableButPossibleNextDayDateTime(formValues.niTime, lastGivenDateTimeMoment).toDate();
    }

    if (formValues.actualPassengers) {
      const finalSeatConfig: string | undefined = Object.values(formValues.actualPassengers).map((item: ISimpleData | any) => {
        if (item?.code && item?.description) {
          return `${item?.code}${item?.description}`;
        }
        return null;
      }).filter(item => item).join('') || '';

      if (finalSeatConfig) {
        newLeg.pax = finalSeatConfig;
      }
    }


    newLeg.arrivalGate = formValues.arrivalgate ? formValues.arrivalgate : null;
    newLeg.arrivalAcStand = formValues.arrivalstand ? formValues.arrivalstand : null;
    newLeg.departureGate = formValues.departuregate ? formValues.departuregate : null;
    newLeg.departureAcStand = formValues.departurestand ? formValues.departurestand : null;
    const passengerCategories: string[] = [];
    for (const key in formValues.categories) {
      if (+formValues.categories[key]?.amount) {
        passengerCategories.push(`${key}:${formValues.categories[key].amount}`);
      }
    }
    newLeg.passengerCategories = '' + passengerCategories.join('/');

    const estimatedPassengers: string[] = [];
    for (const key in formValues.estimatedPassengers) {
      if (+formValues.estimatedPassengers[key]?.description) {
        estimatedPassengers.push(`${key}${formValues.estimatedPassengers[key].description}`);
      }
    }
    newLeg.estimatedPassengers = estimatedPassengers.join('');
    return newLeg;
  }

  private calcApplicableButPossibleNextDayDateTime(currentTimeString: string, lastGivenDateTimeMoment: dayjs.Dayjs): dayjs.Dayjs {
    const currentTimeMoment = dayjs.utc(lastGivenDateTimeMoment.format('YYYY-MM-DD') + '-' + currentTimeString, 'YYYY-MM-DD-HH:mm');
    console.log('Last given :', lastGivenDateTimeMoment.toDate());
    console.log('Current :', currentTimeMoment.toDate());
    if (lastGivenDateTimeMoment.isAfter(currentTimeMoment)) {
      return currentTimeMoment.add(1, 'days');
    }
    return currentTimeMoment;
  }

  get departureDelayListNew(): Record<string, any> {
    const g = this.form?.get('departureDelaysNew') as UntypedFormGroup;

    return g && g.controls ? g.controls : {};
  }

  get arrivalDelayListNew(): Record<string, any> {
    const g = this.form?.get('arrivalDelaysNew') as UntypedFormGroup;

    return g && g.controls ? g.controls : {};
  }


  patchFormArrivalInfo() {
    const etaMoment = this.leg.eta ? dayjs.utc(this.leg.eta) : null;
    const lndMoment = this.leg.landingTime ? dayjs.utc(this.leg.landingTime) : null;
    const ataMoment = this.leg.ata ? dayjs.utc(this.leg.ata) : null;

    this.form?.patchValue({
      etaTime: etaMoment && etaMoment.isValid() ? etaMoment.format('HH:mm') : null,
      lndTime: lndMoment && lndMoment.isValid() ? lndMoment.format('HH:mm') : null,
      ataTime: ataMoment && ataMoment.isValid() ? ataMoment.format('HH:mm') : null,
      arrivalgate: this.leg.arrivalGate,
      arrivalstand: this.leg.arrivalAcStand
    });

    if (this.leg?.arrivalDelays) {
      const arrDelays: ILegDelaysLogModel[] = Object.values(this.leg.arrivalDelays);
      for (let i = 0; i < Math.min(arrDelays.length, 2); i++) {
        const delay = arrDelays[i];
        const ctrl = (this.form?.get("arrivalDelaysNew").get('arrival' + (i + 1)) as UntypedFormGroup);
        ctrl.patchValue({
          delayCode: delay.delayCodeId,
          time: delay.minutes,
        });
      }
    }
  }

  patchFormDepartureInfo() {
    const etaMoment = this.leg.eta ? dayjs.utc(this.leg.eta) : null;
    const etdMoment = this.leg.etd ? dayjs.utc(this.leg.etd) : null;
    const atdMoment = this.leg.atd ? dayjs.utc(this.leg.atd) : null;
    const totMoment = this.leg.takeOffTime ? dayjs.utc(this.leg.takeOffTime) : null;
    const ctotMoment = this.leg.ctot ? dayjs.utc(this.leg.ctot) : null;
    const niMoment = this.leg.ni ? dayjs.utc(this.leg.ni) : null;

    this.form?.patchValue({
      etaTime: etaMoment && etaMoment.isValid() ? etaMoment.format('HH:mm') : null,
      etdDate: etdMoment && etdMoment.isValid() ? dayjsToNgbDate(etdMoment) : dayjsToNgbDate(dayjs.utc(this.leg.std)),
      etdTime: etdMoment && etdMoment.isValid() ? etdMoment.format('HH:mm') : null,
      atdDate: atdMoment && atdMoment.isValid() ? dayjsToNgbDate(atdMoment) : dayjsToNgbDate(dayjs.utc(this.leg.std)),
      atdTime: atdMoment && atdMoment.isValid() ? atdMoment.format('HH:mm') : null,
      totTime: totMoment && totMoment.isValid() ? totMoment.format('HH:mm') : null,
      ctotTime: ctotMoment && ctotMoment.isValid() ? ctotMoment.format('HH:mm') : null,
      niTime: niMoment && niMoment.isValid() ? niMoment.format('HH:mm') : null,
      actualPassengers: this.paxContainer,
      estimatedPassengers: this.calcPaxData(this.leg ? this.leg.estimatedPassengers : null),
      departuregate: this.leg.departureGate,
      departurestand: this.leg.departureAcStand
    });

    if (this.leg?.departureDelays) {
      const depDelays: ILegDelaysLogModel[] = Object.values(this.leg.departureDelays);
      for (let i = 0; i < Math.min(depDelays.length, 4); i++) {
        const delay = depDelays[i];
        const ctrl = (this.form?.get("departureDelaysNew").get('departure' + (i + 1)) as UntypedFormGroup);
        ctrl.patchValue({
          delayCode: delay.delayCodeId,
          time: delay.minutes,
        });
      }
    }

    if (this.leg?.passengerCategories?.length) {
      const passengerData = extractPassengersPerCategory(this.leg.passengerCategories);
      for (const data of passengerData) {
        const subForm: UntypedFormGroup = this.form.get('categories').get(data.code) as UntypedFormGroup;
        subForm?.patchValue({amount: data.amount});
      }
    }
  }

  disableArrivalFormFields() {
    this.form.get('lndTime').disable({emitEvent: false, onlySelf: true});
    this.form.get('ataTime').disable({emitEvent: false, onlySelf: true});
    this.form.get('gate').disable({emitEvent: false, onlySelf: true});
    this.form.get('stand').disable({emitEvent: false, onlySelf: true});
    for (let i = 0; i < 2; i++) {
      this.form.get('arrivalDelaysNew').get('arrival' + (i + 1)).disable({emitEvent: false, onlySelf: true});
    }

  }

  disableDepartureFormFields() {
    this.form.get('etdDate').disable();
    this.form.get('etdTime').disable();
    this.form.get('atdDate').disable();
    this.form.get('atdTime').disable();
    this.form.get('totTime').disable();
    this.form.get('ctotTime').disable();
    this.form.get('niTime').disable();
    this.form.get('actualPassengers').disable();
    this.form.get('estimatedPassengers').disable();
    for (let i = 0; i < 4; i++) {
      this.form.get('departureDelaysNew').get('departure' + (i + 1)).disable({emitEvent: false, onlySelf: true});
    }
  }

  calculateMaxCurrentDelays(autoUpdate = true) {
    this.currentArrivalDelayMinutes = 0;
    this.currentDepartureDelayMinutes = 0;
    this.maxArrivalDelayMinutes = 0;
    this.maxDepartureDelayMinutes = 0;
    if (this.form.getRawValue().atdDate && this.form.getRawValue().atdTime) {
      const time = this.form.getRawValue().atdTime.split(':');
      this.maxDepartureDelayMinutes = ngbDateToDayjs(this.form.getRawValue().atdDate).utc(true).hour(time[0]).minute(time[1]).diff(dayjs(this.leg.std).utc(), 'minutes');
    } else if (this.form.getRawValue().etdDate && this.form.getRawValue().etdTime) {
      const time = this.form.getRawValue().etdTime.split(':');
      this.maxDepartureDelayMinutes = ngbDateToDayjs(this.form.getRawValue().etdDate).utc(true).hour(time[0]).minute(time[1]).diff(dayjs(this.leg.std).utc(), 'minutes');
    } else if (this.leg.etd) {
      this.maxDepartureDelayMinutes = dayjs(this.leg.etd).diff(dayjs(this.leg.std).utc(), 'minutes');
    }
    if (this.form.getRawValue().ataTime) {
      const atdTime: string[] = this.form.getRawValue().atdTime?.split(':');
      let atdDate = ngbDateToDayjs(this.form.getRawValue()?.atdDate);
      if (atdTime?.length === 2 && atdDate.isValid()) {
        atdDate = atdDate.hour(+atdTime[0]).minute(+atdTime[1]);
      } else {
        //atdDate = dayjs.utc();
        atdDate = dayjs.utc(this.leg.tod);
      }
      const estimatedAta = this.calcApplicableButPossibleNextDayDateTime(this.form.getRawValue().ataTime, atdDate);
      this.maxArrivalDelayMinutes = estimatedAta.diff(dayjs.utc(this.leg.sta), 'minutes');
    }
    for (const delay of Object.values(this.form.getRawValue().arrivalDelaysNew) as any[]) {
      if (!delay?.time) {
        continue;
      }
      const minutes = typeof delay.time === 'number' ? delay.time : this.minutesFromTimePipe.transform(delay.time);
      this.currentArrivalDelayMinutes += minutes;
    }
    for (const delay of Object.values(this.form.getRawValue().departureDelaysNew) as any[]) {
      if (!delay?.time) {
        continue;
      }
      const minutes = typeof delay.time === 'number' ? delay.time : this.minutesFromTimePipe.transform(delay.time);
      this.currentDepartureDelayMinutes += minutes;
    }
    if (!autoUpdate) {
      return;
    }
    let dataChanged = false;
    if (this.maxArrivalDelayMinutes !== this.currentArrivalDelayMinutes && this.maxArrivalDelayMinutes > 0) {
      this.form.get('arrivalDelaysNew').patchValue({
        arrival1: {
          delayCode: this.form.get('arrivalDelaysNew').value.arrival1.delayCode || Object.values(this.delayCodes).filter((item) => item.code === '99')[0].id,
          time: this.maxArrivalDelayMinutes,
        }
      });
      dataChanged = true;
    }
    if (this.maxDepartureDelayMinutes !== this.currentDepartureDelayMinutes && this.maxDepartureDelayMinutes > 0) {
      this.form.get('departureDelaysNew').patchValue({
        departure1: {
          delayCode: this.form.get('departureDelaysNew').value.departure1.delayCode || Object.values(this.delayCodes).filter((item) => item.code === '99')[0].id,
          time: this.maxDepartureDelayMinutes,
        }
      });
      dataChanged = true;
    }
    if (dataChanged) {
      this.calculateMaxCurrentDelays(false);
    }
  }

  actualTimeChanged(arrivalSide: boolean) {
    if (arrivalSide) {
      this.form.get('arrivalDelaysNew').patchValue({
        arrival1: {
          time: '',
        },
        arrival2: {
          time: '',
        }
      });
      return;
    }
    //this.form.get('departureDelaysNew').reset();
    this.form.get('departureDelaysNew').patchValue({
      departure1: {
        time: '',
      },
      departure2: {
        time: '',
      },
      departure3: {
        time: '',
      },
      departure4: {
        time: '',
      }
    });
  }

  realTimeInfoTabClicked(tabText: string) {
    if (tabText === this.realTimeInfoOpenTab) {
      this.realTimeInfoOpenTab = null;
      return;
    }
    this.realTimeInfoOpenTab = tabText;
  }

  getRemainingDelay() {
    this.calculateMaxCurrentDelays(false);
    let dataChanged = false;
    if (this.maxDepartureDelayMinutes > this.currentDepartureDelayMinutes) {
      const departureTimeInputs = (this.form.get('departureDelaysNew') as UntypedFormGroup).controls;
      let foundFreeCell = false;
      for (const input of Object.values(departureTimeInputs)) {
        if (!input?.value?.time?.length || input.invalid || input.value.time === '00:00') {
          input.patchValue({time: this.maxDepartureDelayMinutes - this.currentDepartureDelayMinutes});
          foundFreeCell = true;
          break;
        }
      }
      if (!foundFreeCell) {
        departureTimeInputs['departure4'].patchValue({time: this.maxDepartureDelayMinutes - this.currentDepartureDelayMinutes + this.minutesFromTimePipe.transform(departureTimeInputs['departure4'].value.time)});
      }
      dataChanged = true;
    }
    if (this.maxArrivalDelayMinutes > this.currentArrivalDelayMinutes) {
      dataChanged = true;
      const arrivalTimeInputs = (this.form.get('arrivalDelaysNew') as UntypedFormGroup).controls;
      let foundFreeCell = false;
      for (const input of Object.values(arrivalTimeInputs)) {
        if (!input?.value?.time?.length || input.invalid || input.value.time === '00:00') {
          input.patchValue({time: this.maxArrivalDelayMinutes - this.currentArrivalDelayMinutes});
          foundFreeCell = true;
          break;
        }
      }
      if (!foundFreeCell) {
        arrivalTimeInputs['arrival2'].patchValue({time: this.maxArrivalDelayMinutes - this.currentArrivalDelayMinutes + this.minutesFromTimePipe.transform(arrivalTimeInputs['arrival2'].value.time)});
      }
    }
    if (dataChanged) {
      this.calculateMaxCurrentDelays(false);
    }
  }

  returnToRamp(leg: ILegsModel) {

    const modalRef = this.modalService.open(ReturnToRampAirborneDialogComponent, {size: 'xl'});
    const modal = modalRef.componentInstance as ReturnToRampAirborneDialogComponent;
    modal.type = 'RR';
    modal.title = 'Return to Ramp';
    modal.leg = leg;

  }

  returnFromAirborne(leg: ILegsModel) {
    const modalRef = this.modalService.open(ReturnToRampAirborneDialogComponent, {size: 'xl'});
    const modal = modalRef.componentInstance as ReturnToRampAirborneDialogComponent;
    modal.type = 'FR';
    modal.title = 'Return from Airborne';
    modal.leg = leg;
  }

  protected readonly Access = Access;
}
